import React, { useState, useEffect } from 'react';
import { Nav, Navbar, NavDropdown, Container, Image, Dropdown, Modal, Button, Form, InputGroup } from 'react-bootstrap';
import '../assets/scss/NavigationBar.scss';
import Logo from '../assets/images/logo_alliance.png'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { Link, useLocation } from 'react-router-dom';
import '../assets/scss/SpecialNavigation.scss'
import { serverUrl } from '../serverUrl';
import { toast } from "react-toastify";
import Logo2 from '../assets/images/alliance_logo.png'
import { jwtDecode } from 'jwt-decode';

function SpecialNavigation(props) {
    const [scrollPosition, setScrollPosition] = useState(0);
    // get the screen width 
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Add a listener to update windowWidth when the window is resized
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            // Remove the event listener when the component unmounts
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleScroll = () => {
        setScrollPosition(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const location = useLocation();
    const isPhoto = location.pathname.includes('/selected-house/');
    const adminLoginUrl = windowWidth <= 600 ? 'https://landlord.kodinyumba.com' : 'https://web.kodinyumba.com';

    const handleSearch = (event) => {
        props.onData(event.target.value);

    };

    const [showLoginDropdown, setShowLoginDropdown] = useState(false);
    const [showUserLoginModal, setShowUserLoginModal] = useState(false);
    const [showUserRegisterModal, setShowUserRegisterModal] = useState(false);

    const handleLoginClick = () => {
        setShowLoginDropdown(!showLoginDropdown);
    };

    const handleUserLoginClick = () => {
        setShowUserLoginModal(true);
        setShowLoginDropdown(false); // Close the dropdown
    };

    const handleCloseUserLoginModal = () => {
        setShowUserLoginModal(false);
    };


    const handleUserRegisterClick = () => {
        setShowUserRegisterModal(true);

    };

    const handleCloseUserRegisterModal = () => {
        setShowUserRegisterModal(false);
    };

    const [showUserLoginUserModal, setShowUserLoginUserModal] = useState(false);

    const handleUserLoginUserClick = () => {
        setShowUserLoginUserModal(true);

    };

    const handleCloseUserLoginUserModal = () => {
        setShowUserLoginUserModal(false);
    };

    const [value, setValue] = useState('');
    const [valuePhone, setValuePhone] = useState('');

    const handlePhoneInputChange = (phoneValue, country, e, formattedValue) => {
        setValue(phoneValue); // Update the state
    }

    function handleLogin(e) {
        // Prevent the form from redirecting to another page
        e.preventDefault();
        const cleanValue = value.replace(/[^\d+]/g, '');

        if (cleanValue.length > 10) {
            const newValue = '0' + cleanValue.slice(-9);
            setValuePhone(newValue)
            serverUrl.get(`/landlord/alliance-get-user?phone=${newValue}`)
                .then((res) => {
                    if (res.data.count === 0) {
                        // if the user is not found then close the modal and open a new one for them to enter their names email and password
                        handleUserRegisterClick()
                        handleCloseUserLoginModal()
                        setValue('')
                    }
                    else {
                        // if the user is found then get to login them
                        handleUserLoginUserClick()
                        handleCloseUserLoginModal()
                        setValue('')

                    }
                })
        }
    }

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    let [authTokens, setAuthTokens] = useState(() => localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null)
    let [user, setUser] = useState(() => localStorage.getItem('authTokens') ? jwtDecode(localStorage.getItem('authTokens')) : null)

    function handleRegister(e) {
        // Prevent the form from redirecting to another page
        e.preventDefault();
        const data = {
            phone_number: valuePhone,
            full_name: formData.name,
            email: formData.email,
            password: formData.password,
        }
        const loginData = {
            phone_number: valuePhone,
            password: formData.password,

        }
        serverUrl.post(`/users/register/`, data)
            .then((res) => {
                // after user registers login the user
                serverUrl.post(`/users/login/`, loginData)
                    .then((res) => {
                        const data = res.data.data
                        setAuthTokens(data.tokens)
                        setUser(jwtDecode(data.tokens.access))
                        console.log(setUser)
                        localStorage.setItem('authTokens', JSON.stringify(data.tokens))
                        // after the login is succesful then get to add them in the viewers table 
                        const accessToken = localStorage.getItem("authTokens");
                        // get the access token
                        const parsedTokens = JSON.parse(accessToken);
                        const access = parsedTokens.access;
                        // headers access token
                        const config = {
                            headers: { Authorization: `Bearer ${access}` }
                        }
                        // decoding the token so that i can get the user id
                        const decodedToken = jwtDecode(accessToken);
                        const userId = decodedToken.user_id;
                        const viewerData = {
                            user: userId
                        }
                        // check if the user is in the viewers table if not add them if they are then pass
                        serverUrl.get(`/landlord/viewers-leads/?user=${userId}`, config)
                            .then((res) => {
                                if (res.data.count === 0) {
                                    // post the data to the viewers table
                                    serverUrl.post(`/landlord/viewers-leads/`, viewerData, config)
                                        .then((res) => {
                                            handleCloseUserRegisterModal()
                                            setFormData({
                                                password: ''
                                            })
                                            window.location.reload()
                                        })
                                        .catch((error) => {
                                            console.log(error)
                                        })
                                }
                                else {
                                    handleCloseUserRegisterModal()
                                    setFormData({
                                        password: ''
                                    })
                                }

                            })
                            .catch((error) => {
                                console.log(error)
                            })

                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log(error)
            })

            .catch((error) => {
                if (error.response.data.data.status_code === '400') {
                    toast.error(error.response.data.data.error)
                }
                else {
                    console.log(error)
                }
            })
    }

    function handleLoginUser(e) {
        // Prevent the form from redirecting to another page
        e.preventDefault();
        const loginData = {
            phone_number: valuePhone,
            password: formData.password,

        }
        serverUrl.post(`/users/login/`, loginData)
            .then((res) => {
                const data = res.data.data
                setAuthTokens(data.tokens)
                setUser(jwtDecode(data.tokens.access))
                localStorage.setItem('authTokens', JSON.stringify(data.tokens))
                // after the login is succesful then get to add them in the viewers table 
                const accessToken = localStorage.getItem("authTokens");
                // get the access token
                const parsedTokens = JSON.parse(accessToken);
                const access = parsedTokens.access;
                // headers access token
                const config = {
                    headers: { Authorization: `Bearer ${access}` }
                }
                // decoding the token so that i can get the user id
                const decodedToken = jwtDecode(accessToken);
                const userId = decodedToken.user_id;

                const viewerData = {
                    user: userId
                }
                // check if the user is in the viewers table if not add them if they are then pass
                serverUrl.get(`/landlord/viewers-leads/?user=${userId}`, config)
                    .then((res) => {
                        if (res.data.count === 0) {
                            // post the data to the viewers table
                            serverUrl.post(`/landlord/viewers-leads/`, viewerData, config)
                                .then((res) => {
                                    handleCloseUserLoginUserModal()
                                    setFormData({
                                        password: ''
                                    })
                                    window.location.reload()

                                })
                                .catch((error) => {
                                    console.log(error)
                                })
                        }
                        else {
                            handleCloseUserLoginUserModal()
                            setFormData({
                                password: ''
                            })
                        }

                    })
                    .catch((error) => {
                        console.log(error)
                    })

            })
            .catch((error) => {
                console.log(error)
            })
    }

    const [showPassword, setShowPassword] = useState(false);

    const handleTogglePassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };


    // get if the user is loged in then get the imafe
    const accessToken = localStorage.getItem("authTokens");

    const [userImage, setUserImage] = useState('')

    const handleLogout = () => {
        localStorage.removeItem('authTokens');
        toast.success('Successfully logged out!');
        window.location.reload()

    };

    useEffect(() => {
        if (accessToken) {
            // get the access token
            const parsedTokens = JSON.parse(accessToken);
            const access = parsedTokens.access;
            // headers access token
            const config = {
                headers: { Authorization: `Bearer ${access}` }
            }
            // decoding the token so that i can get the user id
            const decodedToken = jwtDecode(accessToken);
            const userId = decodedToken.user_id;
            serverUrl.get(`/landlord/alliance-viewer-lead?user_id=${userId}`, config)
                .then((res) => {
                    setUserImage(res.data.results[0].user_name)
                })
                .catch((error) => {
                    console.log(error)
                })
        }

    }, [])
    const handleFavourite = () => {
        toast.error('Kindly Login so that you can see your favourites')
    }
    return (
        <Navbar
            expand="lg"
            className={`custom-navbar responsive-menu bg-white fixed-top topNavigationStyle`}
        >
            <Container fluid className='topNavbarNavigation'>
                <Navbar.Brand href="/" className={`${scrollPosition > 10 ? 'custom-brand' : 'custom-link'}`}>
                    <Image src={Logo} />
                </Navbar.Brand>


                <Navbar.Toggle aria-controls="basic-navbar-nav" className="custom-toggle" />
                {/* {!isPhoto && (

                    <Navbar.Collapse id="basic-navbar-nav " className=''>

                        <Nav className="m-auto centerNavigation">
                            <Nav.Link href="#home" className={`${scrollPosition > 10 ? 'custom-top-link' : 'custom-link'}`}>
                            </Nav.Link>

                            <InputGroup className="mb-3 topSearchSpecial">
                                <Form.Control placeholder='Start your search' className='border-0' style={{ background: "transparent", height: "125%" }} onChange={handleSearch} />
                                <i className='bi bi-search searchIconNavigation'></i>
                            </InputGroup>
                        </Nav>

                    </Navbar.Collapse>
                )} */}

                <Navbar.Collapse id="basic-navbar-nav">
                    {isPhoto ? (
                        <Nav className="ms-auto">

                            {/* <Nav.Link href="https://web.kodinyumba.com/#/login"  id="basic-nav-dropdown" className={`${scrollPosition > 10 ? 'custom-top-link' : 'custom-link'}`} style={{backgroundColor:'#E6B800',color:"white"}}> */}
                            <Dropdown show={showLoginDropdown} onClose={() => setShowLoginDropdown(false)} className='loginAirbnb' style={{ display: "flex", justifyContent: "right", width: "94%" }}>
                                <Dropdown.Toggle
                                    as={Nav.Link}
                                    className={`${scrollPosition > 10 ? 'custom-top-link' : 'custom-link'} loginIcon`}
                                    onClick={handleLoginClick}
                                >
                                    <span className="bi bi-list me-2"></span>
                                    {accessToken ?
                                        <span>{userImage}</span>
                                        :
                                        <span className="bi bi-person-circle"></span>
                                    }

                                </Dropdown.Toggle>
                                <Dropdown.Menu align="end">
                                    {accessToken ?
                                        <></>
                                        :
                                        <Dropdown.Item onClick={handleUserLoginClick}>User Login</Dropdown.Item>
                                    }
                                    <Dropdown.Item href='https://arl.kodinyumba.com/' >Admin Login</Dropdown.Item>

                                    {accessToken ?

                                        <>
                                            <Dropdown.Item href='/#/favourites' className='' >My favorites</Dropdown.Item>
                                            <Dropdown.Item href='/#/bookings' className='' style={{ borderBottom: "1px solid lightgray" }}>My Bookings</Dropdown.Item>
                                        </>
                                        :
                                        <Dropdown.Item className='' onClick={handleFavourite} style={{ borderBottom: "1px solid lightgray" }}>My favorites</Dropdown.Item>


                                    }
                                    <Dropdown.Item>Help Center</Dropdown.Item>
                                    {accessToken ?
                                        <Dropdown.Item onClick={handleLogout}>Sign Out</Dropdown.Item>
                                        :
                                        <></>
                                    }
                                </Dropdown.Menu>
                            </Dropdown>

                            <Modal show={showUserLoginModal} onHide={handleCloseUserLoginModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        <h6 className='loginText'>Log in or sign up</h6>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body className='loginForm'>
                                    <h5>Welcome to Alliance Realtors</h5>

                                    <Form onSubmit={handleLogin}>

                                        <div className="phone-input-container mb-3">
                                            <label htmlFor="phone-input">Country/Region</label>
                                            <PhoneInput
                                                id="phone-input"
                                                international
                                                countryCallingCodeEditable={false}
                                                defaultCountry="KE"
                                                value={value}
                                                onChange={handlePhoneInputChange}
                                            // disabled

                                            />
                                            <p style={{ fontSize: "0.8rem", marginTop: "0.3rem" }}>We’ll call or text you to confirm your number. Standard message and data rates apply. </p>
                                            <Link className='text-dark' style={{ fontSize: "0.8rem" }}>Privacy Policy</Link>
                                        </div>
                                        <Button variant="warning" className='text-white w-100' type="">
                                            Continue
                                        </Button>
                                    </Form>
                                </Modal.Body>

                            </Modal>

                            {/* the register modal */}
                            <Modal show={showUserRegisterModal} onHide={handleCloseUserRegisterModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        <h6 className='loginText'>SIGN UP</h6>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body className='loginForm'>
                                    <h5>Welcome to Alliance Realtors</h5>

                                    <Form onSubmit={handleRegister}>

                                        <div className="phone-input-container mb-3">


                                            <div className="input-icons">
                                                <i className="fa fa-user icon"></i>
                                                <input
                                                    className="input-field"
                                                    type="text"
                                                    placeholder="Full Name"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>

                                            <div className="input-icons">
                                                <i className="fa fa-envelope icon"></i>
                                                <input
                                                    className="input-field"
                                                    type="email"
                                                    placeholder="Email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>

                                            <div className="input-icons">
                                                <i className="fa fa-lock icon"></i>
                                                <input
                                                    className="input-field"
                                                    type={showPassword ? "text" : "password"}
                                                    placeholder="Password"
                                                    name="password"
                                                    value={formData.password}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <span style={{ cursor: "pointer" }} onClick={handleTogglePassword}>
                                                    {showPassword ? <i className="bi bi-eye-fill" style={{ marginLeft: "-2rem", padding: "13px" }} /> : <i className="bi bi-eye-slash-fill" style={{ marginLeft: "-2rem", padding: "13px" }} />}
                                                </span>

                                            </div>


                                            <p style={{ fontSize: "0.8rem", marginTop: "0.3rem" }}>We’ll call or text you to confirm your number. Standard message and data rates apply. </p>
                                            <Link className='text-dark' style={{ fontSize: "0.8rem" }}>Privacy Policy</Link>
                                        </div>
                                        <Button variant="warning" className='text-white w-100' type="">
                                            Sign Up
                                        </Button>
                                    </Form>
                                </Modal.Body>

                            </Modal>

                            {/* the login user modal */}
                            <Modal show={showUserLoginUserModal} onHide={handleCloseUserLoginUserModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        <h6 className='loginText'>LOGIN</h6>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body className='loginForm'>
                                    <h5>Welcome to Alliance Realtors kindly login</h5>

                                    <Form onSubmit={handleLoginUser}>

                                        <div className="phone-input-container mb-3">
                                            <div className="input-icons">
                                                <i className="fa fa-lock icon"></i>
                                                <input
                                                    className="input-field"
                                                    type={showPassword ? "text" : "password"}
                                                    placeholder="Password"
                                                    name="password"
                                                    value={formData.password}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <span style={{ cursor: "pointer" }} onClick={handleTogglePassword}>
                                                    {showPassword ? <i className="bi bi-eye-fill" style={{ marginLeft: "-2rem", padding: "13px" }} /> : <i className="bi bi-eye-slash-fill" style={{ marginLeft: "-2rem", padding: "13px" }} />}
                                                </span>

                                            </div>


                                            <p style={{ fontSize: "0.8rem", marginTop: "0.3rem" }}>We’ll call or text you to confirm your number. Standard message and data rates apply. </p>
                                            <Link className='text-dark' style={{ fontSize: "0.8rem" }}>Privacy Policy</Link>
                                        </div>
                                        <Button variant="warning" className='text-white w-100' type="">
                                            Login
                                        </Button>
                                    </Form>
                                </Modal.Body>

                            </Modal>


                        </Nav>
                    )
                        : (
                            <Nav className="ms-auto loginButtonSideway">

                                {/* <Nav.Link href="https://web.kodinyumba.com/#/login"  id="basic-nav-dropdown" className={`${scrollPosition > 10 ? 'custom-top-link' : 'custom-link'}`} style={{backgroundColor:'#E6B800',color:"white"}}> */}
                                <Dropdown show={showLoginDropdown} onClose={() => setShowLoginDropdown(false)} className='loginAirbnb' style={{ display: "flex", justifyContent: "right", width: "94%" }}>
                                    <Dropdown.Toggle
                                        as={Nav.Link}
                                        className={`${scrollPosition > 10 ? 'custom-top-link' : 'custom-link'} loginIcon`}
                                        onClick={handleLoginClick}
                                    >
                                        <span className="bi bi-list me-2"></span>
                                        {accessToken ?
                                            <span>{userImage}</span>
                                            :
                                            <span className="bi bi-person-circle"></span>
                                        }

                                    </Dropdown.Toggle>
                                    <Dropdown.Menu align="end">
                                        {accessToken ?
                                            <></>
                                            :
                                            <Dropdown.Item onClick={handleUserLoginClick}>User Login</Dropdown.Item>
                                        }
                                        <Dropdown.Item href='https://arl.kodinyumba.com/' >Admin Login</Dropdown.Item>

                                        {accessToken ?

                                            <>
                                                <Dropdown.Item href='/#/favourites' className='' >My favorites</Dropdown.Item>
                                                <Dropdown.Item href='/#/bookings' className='' style={{ borderBottom: "1px solid lightgray" }}>My Bookings</Dropdown.Item>
                                            </>
                                            :
                                            <Dropdown.Item className='' onClick={handleFavourite} style={{ borderBottom: "1px solid lightgray" }}>My favorites</Dropdown.Item>


                                        }
                                        <Dropdown.Item>Help Center</Dropdown.Item>
                                        {accessToken ?
                                            <Dropdown.Item onClick={handleLogout}>Sign Out</Dropdown.Item>
                                            :
                                            <></>
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>

                                <Modal show={showUserLoginModal} onHide={handleCloseUserLoginModal}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            <h6 className='loginText'>Log in or sign up</h6>
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body className='loginForm'>
                                        <h5>Welcome to Alliance Realtors</h5>

                                        <Form onSubmit={handleLogin}>

                                            <div className="phone-input-container mb-3">
                                                <label htmlFor="phone-input">Country/Region</label>
                                                <PhoneInput
                                                    id="phone-input"
                                                    international
                                                    countryCallingCodeEditable={false}
                                                    defaultCountry="KE"
                                                    value={value}
                                                    onChange={handlePhoneInputChange}
                                                // disabled

                                                />
                                                <p style={{ fontSize: "0.8rem", marginTop: "0.3rem" }}>We’ll call or text you to confirm your number. Standard message and data rates apply. </p>
                                                <Link className='text-dark' style={{ fontSize: "0.8rem" }}>Privacy Policy</Link>
                                            </div>
                                            <Button variant="warning" className='text-white w-100' type="">
                                                Continue
                                            </Button>
                                        </Form>
                                    </Modal.Body>

                                </Modal>

                                {/* the register modal */}
                                <Modal show={showUserRegisterModal} onHide={handleCloseUserRegisterModal}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            <h6 className='loginText'>SIGN UP</h6>
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body className='loginForm'>
                                        <h5>Welcome to Alliance Realtors</h5>

                                        <Form onSubmit={handleRegister}>

                                            <div className="phone-input-container mb-3">


                                                <div className="input-icons">
                                                    <i className="fa fa-user icon"></i>
                                                    <input
                                                        className="input-field"
                                                        type="text"
                                                        placeholder="Full Name"
                                                        name="name"
                                                        value={formData.name}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </div>

                                                <div className="input-icons">
                                                    <i className="fa fa-envelope icon"></i>
                                                    <input
                                                        className="input-field"
                                                        type="email"
                                                        placeholder="Email"
                                                        name="email"
                                                        value={formData.email}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </div>

                                                <div className="input-icons">
                                                    <i className="fa fa-lock icon"></i>
                                                    <input
                                                        className="input-field"
                                                        type={showPassword ? "text" : "password"}
                                                        placeholder="Password"
                                                        name="password"
                                                        value={formData.password}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                    <span style={{ cursor: "pointer" }} onClick={handleTogglePassword}>
                                                        {showPassword ? <i className="bi bi-eye-fill" style={{ marginLeft: "-2rem", padding: "13px" }} /> : <i className="bi bi-eye-slash-fill" style={{ marginLeft: "-2rem", padding: "13px" }} />}
                                                    </span>

                                                </div>


                                                <p style={{ fontSize: "0.8rem", marginTop: "0.3rem" }}>We’ll call or text you to confirm your number. Standard message and data rates apply. </p>
                                                <Link className='text-dark' style={{ fontSize: "0.8rem" }}>Privacy Policy</Link>
                                            </div>
                                            <Button variant="warning" className='text-white w-100' type="">
                                                Sign Up
                                            </Button>
                                        </Form>
                                    </Modal.Body>

                                </Modal>

                                {/* the login user modal */}
                                <Modal show={showUserLoginUserModal} onHide={handleCloseUserLoginUserModal}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            <h6 className='loginText'>LOGIN</h6>
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body className='loginForm'>
                                        <h5>Welcome to Alliance Realtors kindly login</h5>

                                        <Form onSubmit={handleLoginUser}>

                                            <div className="phone-input-container mb-3">
                                                <div className="input-icons">
                                                    <i className="fa fa-lock icon"></i>
                                                    <input
                                                        className="input-field"
                                                        type={showPassword ? "text" : "password"}
                                                        placeholder="Password"
                                                        name="password"
                                                        value={formData.password}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                    <span style={{ cursor: "pointer" }} onClick={handleTogglePassword}>
                                                        {showPassword ? <i className="bi bi-eye-fill" style={{ marginLeft: "-2rem", padding: "13px" }} /> : <i className="bi bi-eye-slash-fill" style={{ marginLeft: "-2rem", padding: "13px" }} />}
                                                    </span>

                                                </div>


                                                <p style={{ fontSize: "0.8rem", marginTop: "0.3rem" }}>We’ll call or text you to confirm your number. Standard message and data rates apply. </p>
                                                <Link className='text-dark' style={{ fontSize: "0.8rem" }}>Privacy Policy</Link>
                                            </div>
                                            <Button variant="warning" className='text-white w-100' type="">
                                                Login
                                            </Button>
                                        </Form>
                                    </Modal.Body>

                                </Modal>


                            </Nav>
                        )}

                </Navbar.Collapse>

            </Container>
        </Navbar>
    );
}

export default SpecialNavigation;
