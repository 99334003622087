import React, { useState, useEffect } from 'react';
import PagesSpecialNavigation from '../components/PagesSpecialNavigation';
import { serverUrl } from '../serverUrl';
import { toast } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';
import { Card, Col, Container, Row, Button, CardBody, Image } from 'react-bootstrap';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../assets/scss/Booking.scss';
import TopMenuFilter from '../components/TopMenuFilter';
import { useParams, Link } from "react-router-dom";


function Bookings() {
  const [bookings, setBookings] = useState([]);
  const [houseSetImage, setHouseSetImage] = useState(null);
  const [selectedHouseDetails, setSelectedHouseDetails] = useState(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,

  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = localStorage.getItem('authTokens');
        if (accessToken) {
          const parsedTokens = JSON.parse(accessToken);
          const access = parsedTokens.access;
          const config = {
            headers: { Authorization: `Bearer ${access}` },
          };
          const decodedToken = jwtDecode(accessToken);
          const userId = decodedToken.user_id;

          const bookingResponse = await serverUrl.get(`/landlord/booking/?user=${userId}`, config);
          setBookings(bookingResponse.data.results);

          // the images for the house
          const imagePromises = bookingResponse.data.results.map(async (booking) => {
            const imageResponse = await serverUrl.get(`/landlord/house-images/?house_id=${booking.house}`, config);
            if (imageResponse.data.count > 0) {
              setHouseSetImage((prevImages) => ({
                ...prevImages,
                [booking.house]: imageResponse.data.results.map((image) => image.image),
              }));
            }
          });

          await Promise.all(imagePromises);

          //  the details of the house details
          const houseDetailsPromises = bookingResponse.data.results.map(async (booking) => {
            const houseResponse = await serverUrl.get(`/landlord/house/${booking.house}/`, config);
            setSelectedHouseDetails((prevHouse) => ({
              ...prevHouse,
              [booking.house]: houseResponse.data,
            }));

          });

          await Promise.all(houseDetailsPromises);

        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const goBack = () => {
    window.history.back();
  };

  function toCamelCase(str) {
    return str.replace(/_/g, ' ');
  }

console.log(selectedHouseDetails[2])
  return (
    <>
      <p className="topNavigation" style={{ padding: "0rem", zIndex: "30000" }} onClick={goBack}>
        <i className="bi bi-arrow-left-circle"></i>
      </p>
      <PagesSpecialNavigation />
      <TopMenuFilter />
      <h2>MY VIEWING'S APPOINTMENTS</h2>

      <div className='bookings'>
        {bookings.map((booking) => (
          <Card className='house'>
            <Card.Body>
              <div className="image-container">
                <Link to={`/selected-house/${booking.house}`} target="_blank" className='text-decoration-none'>

                  {houseSetImage && houseSetImage[booking.house].length > 0 && (
                    <Slider {...settings}>
                      {houseSetImage[booking.house].map((image, index) => (
                        <Image
                          key={index}
                          src={image}
                          className='houseUnitImage'
                          alt='No Image add one kindly'
                        />
                      ))}
                    </Slider>
                  )}
                </Link>
                {selectedHouseDetails &&(

                  <div className="badge text-capitalize">{selectedHouseDetails[booking.house].rent_type}</div>
                )}
                {/* <div className='heartBadge' onClick={() => toggleHearts(unitDetail.house_id)}>
                  {heartState[unitDetail.house_id] ? (
                    <img src={Heart} alt="Heart" />
                  ) : (
                    <img src={Heart2} alt="Heart2" />
                  )}
                </div> */}
                {selectedHouseDetails &&(

                  <Link to={`/selected-house/${booking.house}`} target="_blank" className='text-decoration-none'>

                    <div className="badge_rent text-capitalize">{toCamelCase(selectedHouseDetails[booking.house].type_rent_status)}</div>
                    <div className='d-flex justify-content-between'>
                      <p className="text-start houseTitle" style={{ marginBottom: "0" }} >{booking.house_name}</p>
                    </div>
                  </Link>
                )}

              </div>
              {selectedHouseDetails &&(
                selectedHouseDetails[booking.house].type_rent_status === 'for_sale' ?
                  <div className="houseRentP">Ksh {selectedHouseDetails[booking.house].rent.toLocaleString()}</div>
                  :
                  <div className="houseRentP">Ksh {selectedHouseDetails[booking.house].rent.toLocaleString()}/month</div>
              )}


              {selectedHouseDetails &&(

                <>
                  <p className="houseName fw-normal text-capitalize" style={{ marginBottom: "20px", position: "absolute", bottom: "6%" }}>{toCamelCase(selectedHouseDetails[booking.house].type_of_house)}</p>
                  <div className="d-flex" style={{ paddingTop: "0.8rem" }}>
                    <span className='d-flex' style={{ marginTop: ":1rem" }}>
                      <span style={{ fontSize: "0.8rem", marginRight: "0.4rem", marginTop: ":1rem" }}>{selectedHouseDetails[booking.house].bedroom} Bedrooms</span>
                      <span style={{ fontSize: "1.8rem", marginTop: "-1.2rem" }}>.</span>
                      <span style={{ fontSize: "0.8rem", marginLeft: "0.4rem" }}>{selectedHouseDetails[booking.house].bathroom} Bathroom</span>
                    </span>
                  </div>
                </>
              )}
            </Card.Body>
          </Card>
        ))}

      
      </div>
    </>
  );
}

export default Bookings;
