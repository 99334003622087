import React, { useState } from 'react';
import { Card, Col, Row, Button } from 'react-bootstrap';
import '../../assets/scss/ServiceAgency.scss';

function ServiceAgency() {
  const [selectedParagraph, setSelectedParagraph] = useState('property');

  const handleParagraphClick = (paragraph) => {
    setSelectedParagraph(paragraph);
  };

  return (
    <div className='serviceAgency' id="serviceEstateSection">
      <h2>REAL ESTATE AGENCY</h2>
      <Row>
        <Col md={3} sm={12} xm={12}>
          <Card>
            <Card.Body>
              <p
                onClick={() => handleParagraphClick('property')}
                className={selectedParagraph === 'property' ? 'active-paragraph' : ''}
              >
                <i className='bi bi-house me-1'></i>
                <span>PROPERTY SALES & LETTING</span>
                <i className="bi bi-chevron-right"></i>
              </p>

              <p
                onClick={() => handleParagraphClick('feasibility')}
                className={selectedParagraph === 'feasibility' ? 'active-paragraph' : ''}
              >
                <i className='bi bi-house me-1'></i>
                <span>FEASIBILITY STUDIES</span>
                <i className="bi bi-chevron-right"></i>
              </p>

              <p
                onClick={() => handleParagraphClick('consultancy')}
                className={selectedParagraph === 'consultancy' ? 'active-paragraph' : ''}
              >
                <i className='bi bi-house me-1'></i>
                <span>CONSULTANCY SERVICES</span>
                <i className="bi bi-chevron-right"></i>
              </p>
            </Card.Body>
          </Card>
        </Col>

        <Col md={9} sm={12} xm={12}>
          {selectedParagraph === 'feasibility' && (
            <div className='tabLikeDataAgency'>
              <h2>FEASIBILITY STUDIES</h2>
              <p>We undertake this on various investment proposals...</p>
              <Button>GET STARTED</Button>
            </div>
          )}

          {selectedParagraph === 'property' && (
            <div className='tabLikeDataAgency'>
              <h2>PROPERTY SALES & LETTING</h2>
              <p>In this area, we undertake these services on behalf of our clients...</p>
              <Button>GET STARTED</Button>
            </div>
          )}

          {selectedParagraph === 'consultancy' && (
            <div className='tabLikeDataAgency'>
              <h2>CONSULTANCY SERVICES</h2>
              <p>We advise on lease extensions, change of user & other general real estate consultancy.</p>
              <Button>GET STARTED</Button>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default ServiceAgency;
