import React, { useEffect, useState, useMemo } from 'react'
import '../assets/scss/FeaturedProperties.scss'
import { Card, Col, Image, Row } from 'react-bootstrap'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom'
import { serverUrl, landlordId } from '../serverUrl';

function FeaturedProperties() {
    const [properties, setProperties] = useState([]);
    useEffect(() => {
        serverUrl.get(`/landlord/alliance-apartment?landlord_id=${landlordId}`)
            .then((res) => {
                console.log(res.data.results)
                const filteredData = res.data.results.filter((apartment) => apartment.is_featured === true);
                setProperties(filteredData);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);


    const slidesToShow = properties.length < 3 ? properties.length : 3;
    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    function toCamelCase(str) {
        return str.replace(/_/g, ' ');
    }
    return (
        <div className='featuredProperties bg-light'>
            {properties.length > 0 ?
                <>
                    <h2>Featured Properties</h2>
                    <p>Handpicked properties by our team.</p>
                    <Link to='/listing-page/Featured' style={{ cursor: "pointer" }} className='viewALL'>View all</Link>
                </>
                :
                <>
                </>}


            {/* the display cards of the properties */}
            <Row className='mt-1 justify-content-between'>
                <Slider {...settings}>

                    {properties.map((property) => (
                        <Col md={3} lg={3} sm={6} xs={12} style={{ width: "auto" }}>
                            <Card>
                                <Link to={`/selected-property/${property.id}`} className='text-decoration-none text-dark'>
                                    <Card.Body>
                                        <Image src={property.property_image[0].image} className='propertyImage' />
                                        <div className='d-flex'>

                                            <div className='d-flex topFeaturedButtons'>
                                                <span className='topFeaturedProperties'>Featured</span>
                                                <span className='topRentProperties text-capitalize'>{toCamelCase(property.type_of_rent)}</span>

                                            </div>

                                            <div className='d-flex topFeaturedButtonsLast'>
                                                <span className='topRentProperties'>{property.min_bedroom}Br</span>
                                                <span className='topRentProperties'>{property.max_bedroom} Br</span>

                                            </div>
                                        </div>
                                        {property.type_of_rent === "for_sale" ?
                                            <span className='textRentProperty'>Ksh. {property.max_rent.toLocaleString()}</span>
                                            :
                                            <span className='textRentProperty'>Ksh. {property.max_rent.toLocaleString()}/ month</span>

                                        }
                                        <h6 className='text-capitalize'>{toCamelCase(property.type_of_apartment)}</h6>
                                        <p className='fw-bold'>{property.name}</p>

                                        <p style={{ fontSize: '0.75rem' }}>
                                            <i className='bi bi-geo-alt'></i>
                                            <span>{property.location}</span>
                                        </p>
                                    </Card.Body>
                                </Link>
                            </Card>
                        </Col>


                    ))}
                </Slider>


            </Row>

        </div>
    )
}

export default FeaturedProperties