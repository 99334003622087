import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../assets/scss/TopMenuFilter.scss'
import LA from '../assets/images/LA.jpg'
import { Button, Image, Modal, InputGroup, Row, Col, Form, FormControl, RangeSlider } from 'react-bootstrap';
import HistogramSlider from 'rc-slider';
import { useRef } from 'react';
import * as d3 from 'd3';
import SliderHisto from 'react-slider';
import InputRange from 'react-input-range';
import { serverUrl,landlordId } from '../serverUrl';
import { useNavigate } from 'react-router'



function TopMenuFilter(props) {
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const [propertyType, setPropertyType] = useState('');

  const handlePropertyChange = (e) => {
    setPropertyType(e.target.value);
  };

  const items = [
    {
      description: 'Apartment Units',
      data: "apartment_units",
      image: 'https://kodinyumba.app/media/house_metric_icons/apartment.png',
    },
    {
      description: 'Studio',
      data: "studio",
      image: 'https://kodinyumba.app/media/house_metric_icons/studio.png',
    },
    {
      description: 'Bungalow',
      data: "bungalow",
      image: 'https://kodinyumba.app/media/house_metric_icons/bungalow.png'

    },
    {
      description: 'Bedistter',
      data: "bedsitter",
      image: 'https://kodinyumba.app/media/house_metric_icons/bedsitter.png'
    },
    {
      description: 'Servant Quarters(SQ)',
      data: "servant_quarters",
      image: 'https://kodinyumba.app/media/house_metric_icons/servant-quarter.png'

    },
    {
      description: 'Mansionette',
      data: "mansionette",
      image: 'https://kodinyumba.app/media/house_metric_icons/mansionette.png'

    },
    {
      description: 'Office',
      data: "office",
      image: 'https://kodinyumba.app/media/house_metric_icons/office.png'

    },
    {
      description: 'Stall',
      data: "stall",
      image: 'https://kodinyumba.app/media/house_metric_icons/stall.png'

    },
    {
      description: 'Shop',
      data: "shop",
      image: 'https://kodinyumba.app/media/house_metric_icons/shop.png'

    },
    {
      description: 'All',
      data: "",
      image: 'https://kodinyumba.app/media/house_metric_icons/bungalow.png'

    },

  ];

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 10,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,

        }
      }
    ]
  };

  const [activeButton, setActiveButton] = useState(null);

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };


  const [priceRange, setPriceRange] = useState([100000, 500000]);
  const minPrice = 50000;
  const maxPrice = 1000000;

  const handlePriceChange = (newRange) => {
    setPriceRange(newRange);
  };
  // bedrooms
  const [activeButtonBedroom, setActiveButtonBedroom] = useState('');

  const handleButtonClickBedroom = (buttonName) => {
    setActiveButtonBedroom(buttonName);
  };
  // bathrooms
  const [activeButtonBathrooms, setActiveButtonBathrooms] = useState('');

  const handleButtonClickBathrooms = (buttonName) => {
    setActiveButtonBathrooms(buttonName);
  };

  // past tenants
  const [activeButtonTenants, setActiveButtonTenants] = useState('');

  const handleButtonClickTenants = (buttonName) => {
    setActiveButtonTenants(buttonName);
  };

  // property type
  const [selectedHouses, setSelectedHouses] = useState([]);

  const handleHouseClick = (index) => {
    if (selectedHouses.includes(index)) {
      setSelectedHouses(selectedHouses.filter((item) => item !== index));
    } else {
      setSelectedHouses([...selectedHouses, index]);
    }

  };

  const essentials = [
    {
      name: "Parking",
      image: "https://kodinyumba.app/media/Icons/2023/06/21/signage.png"
    },
    {
      name: "Wifi",
      image: "https://kodinyumba.app/media/Icons/2023/06/21/wifi_1.png"
    },
    {
      name: "Laundry Services",
      image: "https://kodinyumba.app/media/Icons/2023/06/21/washing.png"
    },
    {
      name: "Clothing Line",
      image: "https://kodinyumba.app/media/Icons/2023/06/21/drying.png"
    },
    {
      name: "Swimming Pool",
      image: "https://kodinyumba.app/media/Icons/2023/06/21/swimming-pool.png"
    },
    {
      name: "Air Conditioning",
      image: "https://kodinyumba.app/media/Icons/2023/06/21/air-conditioner.png"
    },
    {
      name: "24 Hour Security",
      image: "https://kodinyumba.app/media/Icons/2023/06/21/guard.png"
    }
  ]
  const [selectedAmenities, setSelectedAmenities] = useState('');

  const handleEssentialChange = (event, description) => {
    if (event.target.checked) {
      const amenitiesToAdd = description.split(', ').join(','); // Split the description into separate amenities and join them as a string
      setSelectedAmenities(prevAmenities => (prevAmenities ? prevAmenities + ',' + amenitiesToAdd : amenitiesToAdd));
    } else {
      const amenitiesToRemove = description.split(', ').join(',');
      setSelectedAmenities(prevAmenities => prevAmenities.replace(amenitiesToRemove, '').replace(/,+/g, ',').replace(/^,|,$/g, '')); // Remove the specified amenities and clean up the string
    }
  };

  const [range, setRange] = useState({ min: 5000, max: 500000 });

  const handleSliderChange = (newRange) => {
    setRange(newRange);
  };

  const handleInputMinChange = (event) => {
    const newMin = parseInt(event.target.value, 10);
    if (newMin >= 0 && newMin <= range.max) {
      setRange({ min: newMin, max: range.max });
    }
  };

  const handleInputMaxChange = (event) => {
    const newMax = parseInt(event.target.value, 10);
    if (newMax <= 500000 && newMax >= range.min) {
      setRange({ min: range.min, max: newMax });
    }
  };

  const resetRange = () => {
    setRange({ min: 5000, max: 500000 });
  };
  const [activeItem, setActiveItem] = useState(null);


  const handleItemClick = (description) => {
    setActiveItem(description)

    props.onData(description);
  };
  const [countHouses, setCountHouses] = useState('')

  //   get the count as the data saves its self
  useEffect(() => {
    // Create an object to store the query parameters
    const queryParams = {
      landlord_id: landlordId,
    };
    // Add query parameters if the corresponding field is not empty
    if (activeButton) {
      queryParams.rent_type = activeButton;
    }

    if (range && range.max != 5000) {
      queryParams.min_price_range = range.min;
    }

    if (range && range.max < 500000) {

      queryParams.max_price_range = range.max;
    }

    if (activeButtonBedroom) {
      queryParams.bedrooms = activeButtonBedroom;
    }

    if (activeButtonBathrooms) {
      queryParams.bathrooms = activeButtonBathrooms;
    }

    if (activeButtonTenants) {
      queryParams.past_tenants = activeButtonTenants;
    }

    if (selectedHouses) {
      queryParams.property_type = selectedHouses;
    }
    if (propertyType) {
      queryParams.apartment_type = propertyType;
    }
    if (selectedAmenities) {
      queryParams.ammenities = selectedAmenities;
    }
    // Pass the data entered
    serverUrl
      .get('/landlord/alliance-unit-search', {
        params: queryParams,
      })
      .then((res) => {
        // save the results in localstorage
        setCountHouses(res.data.count)

      })
      .catch((error) => {
        console.log(error);
      });
  }, [activeButton, range, activeButtonBedroom, activeButtonBathrooms, activeButtonTenants, selectedHouses, propertyType, selectedAmenities]);
  // submit the search
  function handleAdvanced(e) {
    // prevent form from redirecting to another page
    e.preventDefault();
    // Create an object to store the query parameters
    const queryParams = {
      landlord_id: landlordId,
    };
    // Add query parameters if the corresponding field is not empty
    if (activeButton) {
      queryParams.rent_type = activeButton;
    }

    if (range && range.max != 5000) {
      queryParams.min_price_range = range.min;
    }

    if (range && range.max < 500000) {

      queryParams.max_price_range = range.max;
    }

    if (activeButtonBedroom) {
      queryParams.bedrooms = activeButtonBedroom;
    }

    if (activeButtonBathrooms) {
      queryParams.bathrooms = activeButtonBathrooms;
    }

    if (activeButtonTenants) {
      queryParams.past_tenants = activeButtonTenants;
    }

    if (selectedHouses) {
      queryParams.property_type = selectedHouses;
    }
    if (propertyType) {
      queryParams.apartment_type = propertyType;
    }
    if (selectedAmenities) {
      queryParams.ammenities = selectedAmenities;
    }
    serverUrl
      .get('/landlord/alliance-unit-search', {
        params: queryParams,
      })
      .then((res) => {
        // save the results in localstorage
        localStorage.setItem('searchResultsHouses', JSON.stringify(res.data.results));
        navigate('/selected-property/all')
      })
      .catch((error) => {
        console.log(error);
      });

  }
  return (
    <div className='topMenu'>
      <Slider {...settings}>
        {items.map((item, index) => (
          <div key={index}
            className={`item-card ${item.data === activeItem ? 'activeCardItem' : ''}`}
            onClick={() => handleItemClick(item.data)}
          >
            <Image src={item.image} />
            <p>{item.description}</p>

          </div>
        ))}
      </Slider>

      <div className='w-100'>
        <Button
          className='btn-outline-secondary buttonFilter'
          onClick={handleShowModal}
          style={{ borderColor: 'lightgray' }} // Add this style
        >
          <i className="bi bi-sliders me-2"></i> Filters
        </Button>

      </div>

      {/* Modal for filtering*/}
      <Modal
        className='modal-lg'
        show={showModal}
        onHide={handleCloseModal}

        style={{ maxWidth: "100%" }}

      >        <Modal.Header closeButton >
          <h4 style={{ margin: "0 auto", paddingLeft: "20%" }}>Filters</h4>
        </Modal.Header>
        <Form onSubmit={handleAdvanced}>
          <Modal.Body>
            <h4>Type of place</h4>
            <p>Select the type of unit you're interested in based on these parameters.</p>
            <div className='d-flex w-100 justify-content-center' >

              <InputGroup className='justify-content-center'>
                <Button style={{ width: "25%" }}
                  className={`btn btn-outline-dark ${activeButton === 'rent' ? 'typeActive text-white' : 'text-dark bg-transparent'} p-3`}
                  onClick={() => handleButtonClick('rent')}
                >
                  Rent
                </Button>
                <Button style={{ width: "25%" }}
                  className={`btn btn-outline-dark ${activeButton === 'rent_to_own' ? 'typeActive text-white' : 'text-dark bg-transparent'}`}
                  onClick={() => handleButtonClick('rent_to_own')}
                >
                  Rent To Own
                </Button>
                <Button style={{ width: "25%" }}
                  className={`btn btn-outline-dark ${activeButton === 'purchase' ? 'typeActive text-white' : 'text-dark bg-transparent'}`}
                  onClick={() => handleButtonClick('purchase')}
                >
                  Purchase
                </Button>
              </InputGroup>
            </div>
            <div>

              <h4 className='mt-5'>Price range</h4>

              <div>
                <InputRange
                  minValue={5000}
                  maxValue={500000}
                  step={5000}
                  value={range}
                  onChange={handleSliderChange}
                  style={{ width: "90%", margin: "0 auto" }}
                />
                <div className="histogram">
                  <div className="bar">
                    <div
                      className="range"
                      style={{
                        width: `${((range.max - range.min) / 100) * 100}%`,
                        marginLeft: `${(range.min / 100) * 100}%`,
                        backgroundColor: 'yellow',
                      }}
                    ></div>
                  </div>
                </div>
                <div className="input-group mt-3" style={{ width: "95%", marginInline: "0.2rem", placeContent: "center" }}>
                  <span className="currency-label">Ksh</span>
                  <input
                    type="number"
                    className='input-range_input'
                    value={range.min}
                    onChange={handleInputMinChange}
                    style={{ marginRight: "0.5rem" }}
                  />
                  <span className="currency-label">Ksh</span>
                  <input
                    type="number"
                    value={range.max}
                    onChange={handleInputMaxChange}
                  />
                  <Button onClick={resetRange} className='btn btn-warning text-white ms-3' style={{ borderRadius: "3px" }}>Reset</Button>
                </div>

              </div>

              <h4 className='mt-5'>Bathrooms and Bedrooms</h4>
              <p className='mt-3'>Bedrooms</p>

              <div className="d-flex justify-content-between">
                <Button style={{ width: "10%", borderRadius: "20px" }}
                  className={`btn btn-transparent bg-transparent btn-outline-dark ${activeButtonBedroom === '' ? 'activeBedroom' : 'inactiveBedroom'}`}
                  onClick={() => handleButtonClickBedroom('')}
                >
                  Any
                </Button>
                <Button style={{ width: "10%", borderRadius: "20px" }}
                  className={`btn btn-transparent bg-transparent btn-outline-dark ${activeButtonBedroom === '0' ? 'activeBedroom' : 'inactiveBedroom'}`}
                  onClick={() => handleButtonClickBedroom('0')}
                >
                  0
                </Button>
                <Button style={{ width: "10%", borderRadius: "20px" }}
                  className={`btn btn-transparent bg-transparent btn-outline-dark ${activeButtonBedroom === '1' ? 'activeBedroom' : 'inactiveBedroom'}`}
                  onClick={() => handleButtonClickBedroom('1')}
                >
                  1
                </Button>
                <Button style={{ width: "10%", borderRadius: "20px" }}
                  className={`btn btn-transparent bg-transparent btn-outline-dark ${activeButtonBedroom === '2' ? 'activeBedroom' : 'inactiveBedroom'}`}
                  onClick={() => handleButtonClickBedroom('2')}
                >
                  2
                </Button>
                <Button style={{ width: "10%", borderRadius: "20px" }}
                  className={`btn btn-transparent bg-transparent btn-outline-dark ${activeButtonBedroom === '3' ? 'activeBedroom' : 'inactiveBedroom'}`}
                  onClick={() => handleButtonClickBedroom('3')}
                >
                  3
                </Button>
                <Button style={{ width: "10%", borderRadius: "20px" }}
                  className={`btn btn-transparent bg-transparent btn-outline-dark ${activeButtonBedroom === '4' ? 'activeBedroom' : 'inactiveBedroom'}`}
                  onClick={() => handleButtonClickBedroom('4')}
                >
                  4
                </Button>
                <Button style={{ width: "10%", borderRadius: "20px" }}
                  className={`btn btn-transparent bg-transparent btn-outline-dark ${activeButtonBedroom === '5' ? 'activeBedroom' : 'inactiveBedroom'}`}
                  onClick={() => handleButtonClickBedroom('5')}
                >
                  5+
                </Button>
              
              </div>

              <p className='mt-3'>Bathrooms</p>
              <div className="d-flex justify-content-between">
                <Button style={{ width: "10%", borderRadius: "20px" }}
                  className={`btn btn-transparent bg-transparent btn-outline-dark ${activeButtonBathrooms === '' ? 'activeBathroom' : 'inactiveBathroom'}`}
                  onClick={() => handleButtonClickBathrooms('')}
                >
                  Any
                </Button>
                <Button style={{ width: "10%", borderRadius: "20px" }}
                  className={`btn btn-transparent bg-transparent btn-outline-dark ${activeButtonBathrooms === '0' ? 'activeBedroom' : 'inactiveBedroom'}`}
                  onClick={() => handleButtonClickBathrooms('0')}
                >
                  0
                </Button>
                <Button style={{ width: "10%", borderRadius: "20px" }}
                  className={`btn btn-transparent bg-transparent btn-outline-dark ${activeButtonBathrooms === '1' ? 'activeBathroom' : 'inactiveBathroom'}`}
                  onClick={() => handleButtonClickBathrooms('1')}
                >
                  1
                </Button>
                <Button style={{ width: "10%", borderRadius: "20px" }}
                  className={`btn btn-transparent bg-transparent btn-outline-dark ${activeButtonBathrooms === '2' ? 'activeBathroom' : 'inactiveBathroom'}`}
                  onClick={() => handleButtonClickBathrooms('2')}
                >
                  2
                </Button>
                <Button style={{ width: "10%", borderRadius: "20px" }}
                  className={`btn btn-transparent bg-transparent btn-outline-dark ${activeButtonBathrooms === '3' ? 'activeBathroom' : 'inactiveBathroom'}`}
                  onClick={() => handleButtonClickBathrooms('3')}
                >
                  3
                </Button>
                <Button style={{ width: "10%", borderRadius: "20px" }}
                  className={`btn btn-transparent bg-transparent btn-outline-dark ${activeButtonBathrooms === '4' ? 'activeBathroom' : 'inactiveBathroom'}`}
                  onClick={() => handleButtonClickBathrooms('4')}
                >
                  4
                </Button>
                <Button style={{ width: "10%", borderRadius: "20px" }}
                  className={`btn btn-transparent bg-transparent btn-outline-dark ${activeButtonBathrooms === '5' ? 'activeBathroom' : 'inactiveBathroom'}`}
                  onClick={() => handleButtonClickBathrooms('5')}
                >
                  5+
                </Button>
              
              </div>

              <p className='mt-3'>Past Tenants</p>
              <div className="d-flex justify-content-between mb-2">
                <Button style={{ width: "10%", borderRadius: "20px" }}
                  className={`btn btn-transparent bg-transparent btn-outline-dark ${activeButtonTenants === '' ? 'activeTenants' : 'inactiveTenants'}`}
                  onClick={() => handleButtonClickTenants('')}
                >
                  Any
                </Button>
                <Button style={{ width: "10%", borderRadius: "20px" }}
                  className={`btn btn-transparent bg-transparent btn-outline-dark ${activeButtonTenants === '0' ? 'activeBedroom' : 'inactiveBedroom'}`}
                  onClick={() => handleButtonClickBedroom('0')}
                >
                  0
                </Button>
                <Button style={{ width: "10%", borderRadius: "20px" }}
                  className={`btn btn-transparent bg-transparent btn-outline-dark ${activeButtonTenants === '1' ? 'activeTenants' : 'inactiveTenants'}`}
                  onClick={() => handleButtonClickTenants('1')}
                >
                  1
                </Button>
                <Button style={{ width: "10%", borderRadius: "20px" }}
                  className={`btn btn-transparent bg-transparent btn-outline-dark ${activeButtonTenants === '2' ? 'activeTenants' : 'inactiveTenants'}`}
                  onClick={() => handleButtonClickTenants('2')}
                >
                  2
                </Button>
                <Button style={{ width: "10%", borderRadius: "20px" }}
                  className={`btn btn-transparent bg-transparent btn-outline-dark ${activeButtonTenants === '3' ? 'activeTenants' : 'inactiveTenants'}`}
                  onClick={() => handleButtonClickTenants('3')}
                >
                  3
                </Button>
                <Button style={{ width: "10%", borderRadius: "20px" }}
                  className={`btn btn-transparent bg-transparent btn-outline-dark ${activeButtonTenants === '4' ? 'activeTenants' : 'inactiveTenants'}`}
                  onClick={() => handleButtonClickTenants('4')}
                >
                  4
                </Button>
                <Button style={{ width: "10%", borderRadius: "20px" }}
                  className={`btn btn-transparent bg-transparent btn-outline-dark ${activeButtonTenants === '5' ? 'activeTenants' : 'inactiveTenants'}`}
                  onClick={() => handleButtonClickTenants('5')}
                >
                  5+
                </Button>
               
              </div>

              <h4 className='mt-5'>Property type</h4>
              <Row className='mb-2'>
                {items.map((item, index) => (
                  <Col
                    md={3}
                    key={index}
                    className={`item-card ${selectedHouses.includes(item.name) ? 'activePropertyType' : ''}`}
                    onClick={() => handleHouseClick(item.name)}
                  >
                    <Image src={item.image} />
                    <p>{item.description}</p>
                  </Col>
                ))}

              </Row>

              <h4 className='mt-5'>Amenities</h4>
              <h6>Essentials</h6>
              <div className="filters">
                <Row>
                  {essentials.map((essential) => (
                    <Col md={6} className='d-flex' key={essential.name}>
                      <input
                        type='checkbox'
                        style={{ width: "7%" }}
                        className='custom-checkbox bg-warning'
                        onChange={(e) => handleEssentialChange(e, essential.name)}
                      />
                      <label style={{ marginLeft: "0.4rem" }}>{essential.name}</label>
                      <img
                        src={essential.image}
                        style={{ width: "10%", height: "45%", marginTop: "0.rem", marginLeft: "0.4rem" }}
                        alt={essential.name}
                      />
                    </Col>
                  ))}
                </Row>

              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className='d-flex justify-content-between'>
            <Button variant="transparent" className='border-0 fw-bold' style={{ textDecoration: "underline" }} onClick={handleCloseModal}>
              Clear all
            </Button>
            <Button variant="warning" type='submit' className='text-white' onClick={handleCloseModal}>
              Show {countHouses} Units
            </Button>
          </Modal.Footer>
        </Form>

      </Modal>
    </div >
  );
}

export default TopMenuFilter;
