import React, { useEffect, useState } from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';

const MapComponent = ({ google, location }) => {
  const { latitude, longitude} = location;

  const [mapCenter, setMapCenter] = useState({ lat: latitude, lng: longitude });
  const [markerPosition, setMarkerPosition] = useState({ lat: latitude, lng: longitude });
  useEffect(() => {
    setMapCenter({ lat: latitude, lng: longitude });
    setMarkerPosition({ lat: latitude, lng: longitude });

  }, [latitude, longitude]);

  return (
    <div style={{ width: '100%', height: '100%', position: 'static' }}>
      <Map
        google={google}
        className='position-relative'
        zoom={14}
        center={mapCenter}
        style={{ height: '80vh', width: '100%', position: 'relative' }}
      >
        <Marker
          position={markerPosition}
          name='House Location'
        />
      </Map>
    </div>
  );
};


export default GoogleApiWrapper({
  // apiKey: 'AIzaSyDLyN4rhdx4vyZ4PcJlWHXddEV2xaUVpmw',
  apiKey:'AIzaSyBs50rN-7q3YU8ZfGa9hMyNGGZCTyiWU0s'
})(MapComponent);


