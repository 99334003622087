import React, { useState, useEffect } from 'react'
import { serverUrl } from '../serverUrl';
import '../assets/scss/SingleBlog.scss'
import { useParams } from 'react-router-dom';
import SpecialNavigation from '../components/SpecialNavigation';
import { Button, Card, Col, Image, Row } from 'react-bootstrap';

function SingleBlog(props) {
    const { id } = useParams()

    // get the selected blog
    const [blogs, setBlogs] = useState([])
    const [blogImage, setBlogImage] = useState([])

    useEffect(() => {
        serverUrl.get(`/landlord/alliance-blog-image-user?blog_id=${id}`)
            .then((res) => {
                setBlogs(res.data.blogs[0])
            })
            .catch((error) => {
                console.log(error)
            })
        // get the images of that blog
        serverUrl.get(`/landlord/alliance-blog-image?blog_id=${id}`)
            .then((res) => {
                setBlogImage(res.data.results.filter((image) => image.is_main === false))
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])

    const [dataFromSearch, setDataFromSearch] = useState('');
    // Function to handle data from the child
    const handleSearchData = (searchData) => {
        setDataFromSearch(searchData);
    };
    const goBack = () => {
        window.history.back();
      };
      
    return (
        <>
         <p className="topNavigation" onClick={goBack}>
        <i className="bi bi-arrow-left-circle"></i>
      </p>
            <SpecialNavigation onData={handleSearchData} {...props} />
            <div className='singleBlog'>
                <Card>
                    <Button className='btn btn-sm btn-warning text-white'>{blogs.category}</Button>
                    <h1 className='mt-2 text-dark'>{blogs.blog}</h1>

                    <div className='dateUserBlog mb-3 mt-2'>
                        <div>
                            <Image className='me-2' src={blogs.user_profile} style={{ width: "5%" }} />
                            <span >{blogs.user}</span>
                        </div>

                        <div>
                            <i class="bi bi-calendar2-check-fill me-2 text-warning"></i>
                            <span>
                                {new Date(blogs.created).toLocaleDateString('en-US', {
                                    month: 'long',
                                    day: 'numeric',
                                    year: 'numeric'
                                })}
                            </span>
                        </div>


                    </div>

                    <Image src={blogs.blog_image} className='coverImage' />
                    <p>{blogs.description}</p>
                    <p
                        dangerouslySetInnerHTML={{
                          __html: blogs.long_description,
                        }}
                      ></p>
            </Card>

               
            </div>

        </>
    )
}

export default SingleBlog