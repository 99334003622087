import React, { useState, useEffect } from 'react';
import '../assets/scss/Competitive.scss'
import Logo from '../assets/images/logo_alliance.png'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { Link } from 'react-router-dom';
import PagesSpecialNavigation from '../components/PagesSpecialNavigation';
import Footer from '../components/Footer';
import CompetitiveCarousel from '../components/competitive/CompetitiveCarousel';
import CompetitiveFaq from '../components/competitive/CompetitiveFaq';
import { Col, Image, Row } from 'react-bootstrap';
import image1 from '../assets/images/dummy/image4.png'
import image2 from '../assets/images/dummy/image1.png'
import image3 from '../assets/images/dummy/image6.png'
import logo from '../assets/images/logo192.png'
function Competitive() {
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // the dropdown login part
  const [showLoginDropdown, setShowLoginDropdown] = useState(false);
  const [showUserLoginModal, setShowUserLoginModal] = useState(false);

  const handleLoginClick = () => {
    setShowLoginDropdown(!showLoginDropdown);
  };

  const handleUserLoginClick = () => {
    setShowUserLoginModal(true);
    setShowLoginDropdown(false);
  };

  const handleCloseUserLoginModal = () => {
    setShowUserLoginModal(false);
  };

  const [value, setValue] = useState()
  return (
    <>
      <PagesSpecialNavigation />
      <div className='competitive'>
        <CompetitiveCarousel />

       <div className='competitiveDesktop'>
         {/* the middle part */}
         <Row className='firstrow'>
          <Col md={6} sm={12} xm={12} className='imageOne'>
            <Image src={image1}/>
          </Col>

          <Col md={6} sm={12} xm={12} className='paragraphOne'>
            <Image src={logo}/>
            <span>ALLIANCE REALTORS LTD</span>
            <h2>OUR COMMITTMENT</h2>
            <p>At Alliance Realtors Ltd, we are committed to providing the highest quality Real Estate Management and Consultancy services in-order to meet our clients’ property investment needs</p>
            <p>We believe that our clients are our topmost priority and therefore provide services that meet or supersede their expectations. Our services are built on pillars of integrity, professionalism and passion for what we do.</p>
          </Col>
        </Row>

        {/* row two */}
        <Row className='firstrow secondPart'>
          <Col md={6} sm={12} xm={12} className='paragraphOne'>
          <Image src={logo}/>
            <span>ALLAINCE REALTORS LTD</span>
            <h2>OUR TEAM</h2>
            <p>At Alliance Realtors Ltd we pride in having a team with relevant professional qualiﬁ cations,training and experience in their fields of work; which includes:- Land Economists, Accounts and Finance Analysts, Social Scientists, Customer Care Professionals, IT Professionals and other related technical and operational professionals.</p>

            <p>The leading consultants in the company are Registered Valuers, Registered Estate Agents and are full members of the Institution of Surveyors of Kenya (I.S.K.), which is the professional body regulating the activities of Valuers and Estate Agents in Kenya as per the requirements of the Valuers and Estate Agents Act.</p>
          </Col>

          <Col md={6} sm={12} xm={12} className='imageOne'>
            <Image src={image2} />
          </Col>
        </Row>

        {/* row three */}
        <Row className='firstrow'>
          <Col md={6} sm={12} xm={12} className='imageOne'>
            <Image src={image3}/>
          </Col>

          <Col md={6} sm={12} xm={12} className='paragraphOne'>
            <Image src={logo} />
            <span>ALLAINCE REALTORS LTD</span>
            <h2>OUR PROMISE</h2>
            <p>Given the opportunity to serve you, Alliance Realtors Ltd promises to deliver excellent Real Estate Consultancy services. Our professionals will develop an understanding of your business and offer customised service that will suit your current needs and enhance your full potential.</p>
            <p>We will be responsive to your needs and expectations, recognizing that no two clients are alike. Our professionals will demonstrate the highest level of ethical standards and integrity, on which our reputation is built.</p>
          </Col>
        </Row>
       </div>


       <div className='competitiveMobile'>
         {/* the middle part */}
         <Row className='firstrow'>

         <Col md={6} sm={12} xm={12} className='paragraphOne'>
            <Image src={logo}/>
            <span>ALLIANCE REALTORS LTD</span>
            <h2>OUR COMMITTMENT</h2>
            <p>At Alliance Realtors Ltd, we are committed to providing the highest quality Real Estate Management and Consultancy services in-order to meet our clients’ property investment needs</p>
            <p>We believe that our clients are our topmost priority and therefore provide services that meet or supersede their expectations. Our services are built on pillars of integrity, professionalism and passion for what we do.</p>
          </Col>

          <Col md={6} sm={12} xm={12} className='imageOne'>
            <Image src={image1}/>
          </Col>

         
        </Row>

        {/* row two */}
        <Row className='firstrow secondPart'>
          <Col md={6} sm={12} xm={12} className='paragraphOne'>
          <Image src={logo}/>
            <span>ALLAINCE REALTORS LTD</span>
            <h2>OUR TEAM</h2>
            <p>At Alliance Realtors Ltd we pride in having a team with relevant professional qualiﬁ cations,training and experience in their fields of work; which includes:- Land Economists, Accounts and Finance Analysts, Social Scientists, Customer Care Professionals, IT Professionals and other related technical and operational professionals.</p>

            <p>The leading consultants in the company are Registered Valuers, Registered Estate Agents and are full members of the Institution of Surveyors of Kenya (I.S.K.), which is the professional body regulating the activities of Valuers and Estate Agents in Kenya as per the requirements of the Valuers and Estate Agents Act.</p>
          </Col>

          <Col md={6} sm={12} xm={12} className='imageOne'>
            <Image src={image2} />
          </Col>
        </Row>

        {/* row three */}
        <Row className='firstrow'>

        <Col md={6} sm={12} xm={12} className='paragraphOne'>
            <Image src={logo} />
            <span>ALLAINCE REALTORS LTD</span>
            <h2>OUR PROMISE</h2>
            <p>Given the opportunity to serve you, Alliance Realtors Ltd promises to deliver excellent Real Estate Consultancy services. Our professionals will develop an understanding of your business and offer customised service that will suit your current needs and enhance your full potential.</p>
            <p>We will be responsive to your needs and expectations, recognizing that no two clients are alike. Our professionals will demonstrate the highest level of ethical standards and integrity, on which our reputation is built.</p>
          </Col>

          <Col md={6} sm={12} xm={12} className='imageOne'>
            <Image src={image3}/>
          </Col>

         
        </Row>
       </div>


        <CompetitiveFaq />
      </div>
      <Footer />
    </>
  )
}

export default Competitive