import React, { useState, useEffect } from 'react'
import '../assets/scss/WhyUs.scss'
import { Col, Card, Row, Image } from 'react-bootstrap'
import Why1 from '../assets/images/why_1.jpg'
import Why2 from '../assets/images/why_2.jpg'
import Why3 from '../assets/images/why_3.jpg'
import Person1 from '../assets/images/person_1.png'
import Person2 from '../assets/images/person_2.png'
import Person3 from '../assets/images/person_3.png'
import { serverUrl } from '../serverUrl';
import { Link } from 'react-router-dom'


function WhyUs() {
    const [blogs, setBlogs] = useState([])
    const [blogsCount, setBlogsCount] = useState('')

    useEffect(() => {
        serverUrl
            .get(`/landlord/alliance-blog-image-user`)
            .then((res) => {
                setBlogsCount(res.data.count)
                const sortedBlogs = res.data.blogs.sort((a, b) => {
                    const timestampA = new Date(a.timestamp).getTime();
                    const timestampB = new Date(b.timestamp).getTime();
                    return timestampB - timestampA;
                });

                // Get the last three blogs
                const latestThreeBlogs = sortedBlogs.reverse().slice(0, 3);

                setBlogs(latestThreeBlogs)
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);
    return (
        <div className='bg-light whyus'>
            <h3 className='fw-bold'>Why Choose Us</h3>
            <p>We provide full service at every step</p>

            <Row className='mt-5 rowWhyUs'>
                <Col md={4}>
                    <Card>
                        <Card.Body>
                            <span><i class="bi bi-rocket-takeoff-fill"></i></span>
                            <h2>Trusted By Thousands</h2>
                            <p>
                                We've earned the confidence of countless individuals and families on their journey to finding their ideal homes.
                                Our commitment to excellence and customer satisfaction is the cornerstone of our success. Join the ranks of those who have placed their trust in us for
                                all their real estate needs.
                            </p>
                        </Card.Body>
                    </Card>
                </Col>

                <Col md={4}>
                    <Card>
                        <Card.Body>
                            <span><i class="bi bi-buildings-fill"></i></span>
                            <h2>Wide Range Of Properties</h2>
                            <p>We showcases a diverse selection of properties that cater to every tenant's taste and preference. Whether you're in search of a cozy apartment in the heart of the city,
                                a spacious suburban family home, a serene countryside retreat, or a prime commercial space for your business, we have a wide array of options to choose from.</p>
                        </Card.Body>
                    </Card>
                </Col>

                <Col md={4}>
                    <Card>
                        <Card.Body>
                            <span><i class="bi bi-cash-stack"></i></span>
                            <h2>Financing Made Easy</h2>
                            <p>Securing the right financing for your property just got simpler with Alliance Realtors. Our team of experts ensures a hassle-free experience, offering personalized guidance,
                                competitive rates, and complete transparency. With fast approvals and diverse financing options, we're your trusted partner on the path to property ownership.</p>
                        </Card.Body>
                    </Card>
                </Col>

            </Row>
            {blogs.length === 0 ?
                <></>
                :

                <>
                    <h3 className='fw-bold mt-5'>Articles & Tips</h3>
                    <p>Explore our curated collection of insightful articles and expert tips for all things real estate.</p>
                    {blogsCount > 3 ?
                        <Link className='text-warning fw-bold' to={'/all-blogs'}>View all</Link>
                        :
                        <></>
                    }
                    <Row className='mt-5 rowArticles'>
                        {blogs.map(blog => (

                            <Col md={4}>
                                <Link to={`/single-blog/${blog.id}`} className='text-decoration-none'>
                                    <Card>
                                        <Card.Body style={{height:"60vh"}}>
                                            <Image src={blog.blog_image} />
                                            <div className='d-flex justify-content-between pt-3 '>
                                                <h2>{blog.blog.length > 50 ? blog.description.substring(0, 50) + '...' : blog.description}</h2>

                                                <h2 className='btn btn-success btn-sm opacity-75 text-white'>{blog.category}</h2>

                                            </div>
                                            <p>{blog.description.length > 150 ? blog.description.substring(0, 150) + '...' : blog.description}</p>
                                        </Card.Body>

                                        <Card.Footer>
                                            <div className='footerWhyUs'>
                                                <Image src={blog.user_profile} />
                                                <p>{blog.user}</p>
                                            </div>

                                            <p>{new Date(blog.created).toLocaleDateString('en-US', {
                                                month: 'long',
                                                day: 'numeric',
                                                year: '2-digit'
                                            })}</p>
                                        </Card.Footer>
                                    </Card>
                                </Link>

                            </Col>
                        ))}
                    </Row>

                </>
            }


        </div>
    )
}

export default WhyUs