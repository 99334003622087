import axios from "axios";

export let landlordId;
export let baseUrl;

if (process.env.NODE_ENV === "development") {
  // baseUrl = "http://127.0.0.1:8000/api";
  baseUrl = "https://kodinyumba.app/api";
  // landlordId = 1;
  landlordId=56
} else {
  baseUrl = "https://kodinyumba.app/api";
  landlordId = 56; 
}

export const serverUrl = axios.create({
  baseURL: baseUrl,
});
