import React, { useState } from 'react'
import '../assets/scss/Footer.scss'
import { Col, Container, NavLink, Row, Image, Modal, Form, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Logo from '../assets/images/logo_1.png'

function Footer() {
    // the dropdown login part
    const [showLoginDropdown, setShowLoginDropdown] = useState(false);
    const [showUserLoginModal, setShowUserLoginModal] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState('KSH');

    const handleUserLoginClick = () => {
        setShowUserLoginModal(true);
    };

    const handleCloseUserLoginModal = () => {
        setShowUserLoginModal(false);
    };

    const handleCurrencySelection = (currency) => {
        setSelectedCurrency(currency);
    };

    return (

        <>

            <div className='footer'>
                <Container fluid style={{width:"80%"}}>
                    <Row>
                        <Col sm={6} md={6} lg={2} xl={2}>
                            <Image src={Logo} className='imageLogoFooter' />
                        </Col>
                        <Col sm={6} md={6} lg={3} xl={3}>
                            <h2>Head Offices - Nairobi</h2>
                            <p>
                                Salama House, Mama Ngina Street, Wabera Street Junction
                            </p>
                            <p>
                                P.O. Box 17100 - 00100 Nairobi
                            </p>
                            <p>
                                Phone No: 0722 498 500 / 0720 144 566
                            </p>
                            <p>
                                Email: info@alliancerealtorsltd.co.ke
                            </p>
                        </Col>

                        <Col sm={6} md={6} lg={3} xl={3}>
                            <h2>Embakasi Area Branch</h2>
                            <Link>Fedha Service Station Plaza, Ground Flr</Link>
                            <br></br>

                            <Link>P.O. Box 17100 - 00100 Nairobi</Link>
                            <br></br>

                            <Link>Phone No: 0701 40 44 40</Link>
                            <br></br>
                            <p>
                                Email: embakasi@alliancerealtorsltd.co.ke
                            </p>
                            <Link>Support Center</Link>


                        </Col>

                        <Col sm={6} md={6} lg={2} xl={2}>
                            <h2>Kitengela</h2>
                            <Link>Eagle House, 2nd Flr</Link>
                            <br></br>

                            <Link>P.O. Box 17100 - 00100 Nairobi</Link>
                            <br></br>

                            <Link>Phone No: 0798 91 09 10</Link>
                            <br></br>

                          
                        </Col>

                        <Col sm={6} md={6} lg={2} xl={2}>
                            <h2>Kiambu Area Branch</h2>
                            <Link>Feruzi Towers, 2nd Flr</Link>
                            <br></br>

                            <Link>P.O. Box 17100 - 00100 Nairobi</Link>
                            <br></br>

                            <Link>Phone No: 0757 35 55 57</Link>
                            <br></br>

                            <p>
                                Email: embakasi@alliancerealtorsltd.co.ke
                            </p>
                        </Col>

                        <Col sm={6} md={6} lg={2} xl={2}>
                            <h2>Diaspora Liason</h2>
                            <Link>Phone No: +14 84-995-7841</Link>
                            <br></br>

                            <Link>Philadelphia,</Link>
                            <br></br>

                            <Link>USA</Link>
                            <br></br>
                        </Col>
                    </Row>
                </Container>

                <Container fluid className='lowerFooter'>
                    <div className='d-flex' style={{ marginLeft: "6rem" }}>
                        <p className='mb-0'>Interact with us through:</p>
                        <Link to={`https://www.instagram.com/alliancerealtorsltd/`}>
                            <i class="bi bi-instagram text-warning "></i>
                        </Link>
                        <Link to={`https://web.facebook.com/alliancerealtorsltd`}>
                            <i class="bi bi-facebook text-warning"></i>
                        </Link>
                    </div>

                    <div className='d-flex'>
                        <p onClick={handleUserLoginClick} className='' style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                            {selectedCurrency}<br />
                        </p>

                        <Modal show={showUserLoginModal} onHide={handleCloseUserLoginModal}>
                            <Modal.Header closeButton>
                                <Modal.Title>Choose a currency</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <h5>Select your currency</h5>
                                <Form>
                                    <div className="d-flex mb-3">
                                        <Button
                                            className={`btn btn-outline-dark bg-transparent text-dark footer_buttons me-3`}
                                            onClick={() => handleCurrencySelection('KSH')}
                                        >
                                            Kenya Shillings<br />
                                            <span className='float-start'>KSH</span>
                                        </Button>
                                        <Button
                                            className={`btn btn-outline-dark bg-transparent text-dark footer_buttons`}
                                            onClick={() => handleCurrencySelection('USD - $')}
                                        >
                                            United States dollar<br />
                                            <span className='float-start'>USD - $</span>
                                        </Button>
                                    </div>
                                </Form>
                            </Modal.Body>
                        </Modal>

                        <Link to='https://kodinyumba.com/'>
                            <p>© 2023 powered by Kodi. All rights reserved.</p>

                        </Link>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default Footer