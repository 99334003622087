import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import '../../assets/scss/ServiceValuation.scss'

function ServiceValuation() {
    return (
        <div className='propertyAsset' id="serviceAssetSection">
            <div className='topasset'>
                <h2>PROPERTY & ASSET VALUATION</h2>
                <p>Our Valuers have gained vast experience in the field of property/asset valuation and have carried out many valuation assignments for a host of clients including large corporate organizations. The types of valuations carried out include:</p>

            </div>
            <Row>
                <Col md={4} sm={12} xm={12} className='custom-col'>
                    <i class="bi bi-hand-thumbs-up-fill"></i>
                    <h2>MORTGAGE VALUATIONS</h2>
                    <Button>CONTINUE</Button>
                </Col>

                <Col md={4} sm={12} xm={12} className='custom-col'>
                    <i class="bi bi-hand-thumbs-up-fill"></i>
                    <h2>RENTAL VALUATIONS</h2>
                    <Button>CONTINUE</Button>
                </Col>

                <Col md={4} sm={12} xm={12} className='custom-col'>
                    <i class="bi bi-hand-thumbs-up-fill"></i>
                    <h2>OPEN MARKET VALUATIONS</h2>
                    <Button>CONTINUE</Button>
                </Col>
            </Row>

            <Row>
                <Col md={4} sm={12} xm={12} className='custom-col'>
                <i class="bi bi-hand-thumbs-up-fill"></i>
                    <h2>INSURANCE VALUATIONS</h2>
                    <Button>CONTINUE</Button>
                </Col>

                <Col md={4} sm={12} xm={12} className='custom-col'>
                <i class="bi bi-hand-thumbs-up-fill"></i>
                    <h2>FORCED SALE VALUATIONS</h2>
                    <Button>CONTINUE</Button>
                </Col>

                <Col md={4} sm={12} xm={12} className='custom-col'>
                <i class="bi bi-hand-thumbs-up-fill"></i>
                    <h2>VALUATION OF PLANT & MACHINERY</h2>
                    <Button>CONTINUE</Button>
                </Col>
            </Row>
        </div>
    )
}

export default ServiceValuation