import React, { useState, useRef } from "react";
import "../assets/scss/Services.scss";
import Static from "../assets/images/static.jpg";
import PagesSpecialNavigation from "../components/PagesSpecialNavigation";
import { Card, Col, Row, Button } from "react-bootstrap";
import ServiceValuation from "../components/services/ServiceValuation";
import ServiceManagement from "../components/services/ServiceManagement";
import ServiceAgency from "../components/services/ServiceAgency";
import Footer from "../components/Footer";

function Services() {
  const [isHovered, setHovered] = useState(false);
  const [isClicked, setClicked] = useState(false);
  const [activeFunction, setActiveFunction] = useState(null);
  const [isHoveredAsset, setHoveredAsset] = useState(false);
  const [isClickedAsset, setClickedAsset] = useState(false);
  const [isHoveredEstate, setHoveredEstate] = useState(false);
  const [isClickedEstate, setClickedEstate] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseEnterAsset = () => {
    setHoveredAsset(true)
  };

  const handleMouseEnterEstate = () => {
    setHoveredEstate(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);

  };
  const handleMouseLeaveEstate = () => {
    setHoveredEstate(false);

  };

  const handleMouseLeaveAsset = () => {
    setHoveredAsset(false)

  };

  const handleClickAsset = (selector) => {
    setClickedAsset(!isClickedAsset)
    setActiveFunction('asset');

    const element = document.getElementById(selector);

    if (element) {
      const offset = 0;
      const elementPosition = element.offsetTop - offset;

      window.scrollTo({
        top: elementPosition,
        behavior: "smooth",
      });
    }
  };

  const handleClickEstate = (selector) => {
    setClickedEstate(!isClickedEstate)
    setActiveFunction('estate');

    const element = document.getElementById(selector);

    if (element) {
      const offset = 0;
      const elementPosition = element.offsetTop - offset;

      window.scrollTo({
        top: elementPosition,
        behavior: "smooth",
      });
    }
  };

  const handleClick = (selector, offset = 0) => {
    setClicked(!isClicked);
    setActiveFunction('default');

    const element = document.getElementById(selector);
  
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
        offsetTop: offset,
      });
    }
  };
  
  
  

  return (
    <>
      <PagesSpecialNavigation />
      <div className="services">
        <div
          className="topServices"
          style={{ backgroundImage: `url(${Static})` }}
        >
          <h2>OUR SERVICES</h2>
        </div>

        <Row className="rowCardServices">
          <Col md={4} sm={12} xm={12}>
            <Card
              style={{
                backgroundColor: isClicked
                  ? "green"
                  : isHovered
                  ? "lightgray"
                  : "#dad6d65e",
              }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleClick("serviceManagementSection",0)}
            >
              <h2>PROPERTY & Estate MANAGEMENT</h2>
              <p
                style={{
                  color: isClicked
                    ? "#e6b800"
                    : isHovered
                    ? "black"
                    : "#4e6f49",
                }}
              >
                We offer professional expertise in management of sensitive
                commercial & residential properties including
                controlled/protected tenancies.
              </p>
              <Button
                style={{
                  backgroundColor: isClicked
                    ? "#e6b800"
                    : isHovered
                    ? "transparent"
                    : "transparent",
                }}
              >
                CONTINUE
              </Button>
            </Card>
          </Col>

          <Col md={4} sm={12} xm={12}>
            <Card
              style={{
                backgroundColor: isClickedAsset
                  ? "green"
                  : isHoveredAsset
                  ? "lightgray"
                  : "#dad6d65e",
              }}
              onMouseEnter={handleMouseEnterAsset}
              onMouseLeave={handleMouseLeaveAsset}
              onClick={() => handleClickAsset("serviceAssetSection")}
            >
              <h2>PROPERTY & ASSET VALUATION</h2>

              <p
                style={{
                  color: isClickedAsset
                    ? "#e6b800"
                    : isHoveredAsset
                    ? "black"
                    : "#4e6f49",
                }}
              >
                Our valuers have gained vast experience in the field and have
                carried out many valuation assignments for a host of clients
                including large corporate organizations.
              </p>
              <Button
               style={{
                backgroundColor: isClickedAsset
                  ? "#e6b800"
                  : isHoveredAsset
                  ? "transparent"
                  : "transparent",
              }}
              >CONTINUE</Button>
            </Card>
          </Col>

          <Col md={4} sm={12} xm={12}>
          <Card
              style={{
                backgroundColor: isClickedEstate
                  ? "green"
                  : isHoveredEstate
                  ? "lightgray"
                  : "#dad6d65e",
              }}
              onMouseEnter={handleMouseEnterEstate}
              onMouseLeave={handleMouseLeaveEstate}
              onClick={() => handleClickEstate("serviceEstateSection")}
            >
              <h2>REAL ESTATE AGENCY</h2>
              <p
               style={{
                color: isClickedEstate
                  ? "#e6b800"
                  : isHoveredEstate
                  ? "black"
                  : "#4e6f49",
              }}
              >
                In this area, we undertake these services on behalf of our
                clients for a commission. We use our professional skills in
                selecting the most suitable tenants.
              </p>
              <Button
                   style={{
                    backgroundColor: isClickedEstate
                      ? "#e6b800"
                      : isHoveredEstate
                      ? "transparent"
                      : "transparent",
                  }}
              >CONTINUE</Button>
            </Card>
          </Col>
        </Row>

        <ServiceValuation />
        <ServiceManagement />
        <ServiceAgency />

        <Row className="bottomCardInfo">
          <Col md={3} sm={6} xm={6}>
            <Card>
              <i class="bi bi-geo-alt-fill"></i>
              <div>
                <span>OUR HEADQUARTERS</span>
                <p>Salama House,Mama Ngina Street,</p>
              </div>
            </Card>
          </Col>

          <Col md={3} sm={6} xm={6}>
            <Card>
              <i class="bi bi-telephone-fill"></i>
              <div>
                <span>SPEAK TO US</span>
                <p>0722 498 500</p>
                <p>0720 144 566</p>
              </div>
            </Card>
          </Col>

          <Col md={3} sm={6} xm={6}>
            <Card>
              <i class="bi bi-chat-left-text-fill"></i>
              <div>
                <span>WRITE TO US</span>
                <p>P.O. Box 17100 - 00100 Nairobi</p>
              </div>
            </Card>
          </Col>

          <Col md={3} sm={6} xm={6}>
            <Card>
              <i class="bi bi-twitter"></i>
              <div>
                <span>FOLLOW US ON SOCIAL</span>
                <p>@alliancerealtorsltd alliancerealtorsltd</p>
              </div>
            </Card>
          </Col>
        </Row>

        <Footer />
      </div>
    </>
  );
}

export default Services;
