import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import '../../assets/scss/CompetitiveFaq.scss'

function CompetitiveFaq() {
    return (
        <div className='competitivefaq'>
            <Row className='topRow'>
                <Col md={4} sm={12} xm={12}>
                    <i class="bi bi-cart-fill"></i><br></br>
                    <span>GET STARTED</span>
                    <h2>BUY & RENT PROPERTY</h2>
                </Col>

                <Col md={4} sm={12} xm={12}>
                    <i class="bi bi-chat-left-dots-fill"></i><br></br>
                    <span>GET STARTED</span>
                    <h2>SPEAK TO AN AGENT</h2>
                </Col>

                <Col md={4} sm={12} xm={12}>
                    <i class="bi bi-house-fill"></i><br></br>
                    <span>GET STARTED</span>
                    <h2>REAL ESTATE CONSULTING</h2>
                </Col>
            </Row>

            {/* FAQ */}
            <div className='faqContainer'>
                <h2 className='text-start'>FREQUENTLY ASKED QUESTIONS</h2>
                <Row>
                    <Col md={6} sm={12} xm={12}>
                        <div>
                            <h3>What the first step of the home buying process?</h3>
                            <p>Getting pre-approved for a mortgage is the first step of the home buying process. Getting a pre-approval letter from a lender get the ball rolling in the right direction.</p>
                            <Link>Get Further Assistance <i class="bi bi-arrow-right"></i></Link>
                        </div>

                        <div>
                            <h3>What kind of credit score do I need to buy a home?</h3>
                            <p>Most loan programs require a FICO score of 620 or better. Borrowers with higher credit scores represent less risk to the lender, often resulting in a lower the down payment requirement and better interest rate.</p>
                            <p>Conversely, home shoppers with lower credit scores may need to bring more money to the table (or accept a higher interest rate) to offset the lender’s risk.</p>
                            <Link>Get Further Assistance <i class="bi bi-arrow-right"></i></Link>
                        </div>

                        <div>
                            <h3>Do I need to do a final walk-through?</h3>
                            <p>It’s not required, but it’s a darn good idea! Final walk-throughs give buyers a chance to make sure nothing had changed since their first visit.</p>
                            <p>If repairs were requested, as part of the offer, a follow-up visit ensures that everything is squared-away, as expected, per the terms of the contract.</p>
                            <Link>Get Further Assistance <i class="bi bi-arrow-right"></i></Link>
                        </div>
                    </Col>

                    <Col md={6} sm={12} xm={12}>
                        <div>
                            <h3>How long does it take to buy a home?</h3>
                            <p>From start to finish, buying a home takes about 10 to 12 weeks. Once a home is selected an the offer is accepted, the average time to complete the escrow period on a home is 30 to 45 days.</p>
                            <Link>Get Further Assistance <i className='bi bi-house'></i></Link>
                        </div>

                        <div>
                            <h3>How many homes should I view before buying one?</h3>
                            <p>That’s up to you! For sure, home shopping today is easier today than ever before.</p>
                            <p>The ability to search for homes online and see pictures, even before setting a foot outside the comfort of your living room, has completely changed the home buying game.</p>
                            <Link>Get Further Assistance <i className='bi bi-house'></i></Link>
                        </div>

                        <div>
                            <h3>What is earnest money?</h3>
                            <p>When you make an offer on a home, your agent will ask for a check to accompany it (checks are the same as cash, and the deposit is typically 1% to 2% of the purchase price).</p>
                            <p>Earnest money is made in good faith to demonstrate - to the seller - that the buyer’s offer is genuine. Earnest money essentially takes the home off the market to anyone else and reserves it for you.</p>
                            <Link>Get Further Assistance <i className='bi bi-house'></i></Link>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default CompetitiveFaq