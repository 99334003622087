import React, { useState, useEffect } from 'react'
import { Image, Card, FormSelect, Button, Row, Col, Form, FormControl, Modal, Dropdown } from 'react-bootstrap';
import '../assets/scss/SelectedProperty.scss'
import SideBar from '../components/SideMenu'
import Footer from '../components/Footer'
import NavigationBar from '../components/NavigationBar';
import { serverUrl } from '../serverUrl';
import { useParams, Link } from "react-router-dom";
import SpecialNavigation from '../components/SpecialNavigation';
import Heart from '../assets/images/heart__1_.png'
import Heart2 from '../assets/images/heart.png'
import Star from '../assets/images/star.png'
import Map from '../assets/images/map.png'
import Grid from '../assets/images/grid.png'
import TopMenuFilter from '../components/TopMenuFilter';
import Slider from 'react-slick';
import { Carousel } from 'react-bootstrap';
import { jwtDecode } from 'jwt-decode';
import { toast } from "react-toastify";


function SelectedProperty(props) {
    const { id } = useParams();
    const [dataFromChild, setDataFromChild] = useState('');
    // Function to handle data from the child
    const handleChildData = (childData) => {
        setDataFromChild(childData);
    };
    const [dataFromSearch, setDataFromSearch] = useState('');
    // Function to handle data from the child
    const handleSearchData = (searchData) => {
        setDataFromSearch(searchData);
    };
    // loading status
    const [isLoading, setIsLoading] = useState(true)

    // after the login is succesful then get to add them in the viewers table 
    const accessToken = localStorage.getItem("authTokens");
    if (accessToken) {
        // get the access token
        const parsedTokens = JSON.parse(accessToken);
        const access = parsedTokens.access;
        // headers access token
        const config = {
            headers: { Authorization: `Bearer ${access}` }
        }
        // decoding the token so that i can get the user id
        const decodedToken = jwtDecode(accessToken);
        const userId = decodedToken.user_id;
    }


    // apartment
    const [selectedApartment, setSelectedApartment] = useState([])
    const [apartmentImage, setApartmentImage] = useState("")
    // randomise for each House
    const [backgroundImages, setBackgroundImages] = useState({});
    const amenitiesApartment = [
        {
            name: "Court",
            icon: `https://kodinyumba.app/media/Icons/2023/06/21/basketball-court.png`
        },
        {
            name: "CCTV",
            icon: `https://kodinyumba.app/media/Icons/2023/06/21/cctv.png`
        },
        {
            name: "WiFi",
            icon: `https://kodinyumba.app/media/Icons/2023/06/21/wifi_1.png`
        },
        {
            name: "Balcony",
            icon: `https://kodinyumba.app/media/Icons/2023/06/21/balcony.png`
        },
        {
            name: "Gym",
            icon: `https://kodinyumba.app/media/Icons/2023/06/21/gym.png`
        },
    ]
    const [housesApartment, setHouseApartment] = useState([])

    const [selectedPropertyDetails, setSelectedProperty] = useState({})


    const [selectedHouses, setSelectedHouses] = useState([]);
    const [selectedAmenities, setSelectedAmenities] = useState([]);
    const [selectedApartmentImages, setSelectedApartmentImages] = useState([]);


    // get the houses 
    const [nearbyFacilities, setNearbyFacilities] = useState([])
    const [heartState, setHeartState] = useState({});
    const [currentUser,setCurrentUser] = useState([])

    useEffect(() => {
        // get the nearby facilities
        serverUrl.get(`/landlord/alliance-facilities-apartment-allowany?apartment_id=${id}`)
            .then((res) => {
                setNearbyFacilities(res.data.results)
            })
            .catch((error) => {
                console.log(error)
            })

        if (id == 'all') {
            const saved = localStorage.getItem("searchResultsHouses");
            const initialValue = JSON.parse(saved)
            setHouseApartment(initialValue)
        }
        else {

            serverUrl.get(`/landlord/alliance-house?property_id=${id}`)
                .then((res) => {
                    setHouseApartment(res.data.results.filter((house) => house.is_public === true))
                    setSelectedAmenities(res.data.amenities)
                    console.log(res.data.amenities)
                })
                .catch((error) => [
                    console.log(error)
                ])
            // get the apartment
            serverUrl.get(`/landlord/alliance-specific-apartment?property_id=${id}`)
                .then((res) => {
                    setSelectedProperty(res.data.results[0])
                    setSelectedApartmentImages(res.data.results[0].property_image)
                })
                .catch((error) => {
                    console.log(error)
                })
        }
        if (accessToken) {
            // check first if user is logged in
            const parsedTokens = JSON.parse(accessToken);
            const access = parsedTokens.access;
            // headers access token
            const config = {
                headers: { Authorization: `Bearer ${access}` }
            }
            // decoding the token so that i can get the user id
            const decodedToken = jwtDecode(accessToken);
            const userId = decodedToken.user_id;

             // get the current user
             serverUrl.get(`/users/users-details/${userId}/`, config)
             .then((res => {
                 setCurrentUser(res.data)
             }))
             .catch((error) => {
                 console.log(error)
             })

            // Fetch liked units for the user based on their userId.
            serverUrl
                .get(`/landlord/viewers-favourites/?user=${userId}`, config)
                .then((res) => {
                    // Process the response to determine which units are liked by the user.
                    const likedUnits = res.data.results.map((likedUnit) => likedUnit.house);


                    // Update the heartState based on the likedUnits.
                    const newHeartState = { ...heartState };
                    likedUnits.forEach((unitId) => {
                        newHeartState[unitId] = true;
                    });
                    setHeartState(newHeartState);

                })
                .catch((error) => {
                    console.log(error);
                });
        }

    }, [])
    // randomise the images for the apartment
    const [backgroundImageApartment, setBackgroundImageApartment] = useState('');
    useEffect(() => {
        const getRandomImage = () => {
            let storedImage = localStorage.getItem('backgroundImage');
            if (!storedImage) {
                storedImage = '';
            }

            let randomNumber = Math.floor(Math.random() * 10) + 1;
            const invalidNumbers = [0, -1];
            while (invalidNumbers.includes(randomNumber) || storedImage.endsWith(`${randomNumber}.jpg`)) {
                randomNumber = Math.floor(Math.random() * 10) + 1;
            }

            const imageUrl = `https://kodinyumba.app/media/Stock/hd/Property/apartment${randomNumber}.jpg`;
            setBackgroundImageApartment(imageUrl);
            localStorage.setItem('backgroundImage', imageUrl);
            setIsLoading(false);
        };

        getRandomImage();
    }, []);

    // Helper function to check if any field in the item contains the search query
    function containsSearchQuery(item, dataFromSearch) {
        const searchableFields = Object.values(item).join(" ").toLowerCase();
        return searchableFields.includes(dataFromSearch.toLowerCase());
    }
    // design the table to display 10 each per table
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 12;
    const filteredData = housesApartment.filter((item) => {
        // Filter by selected property type if dataFromChild is not blank
        if (dataFromChild && item.type_of_house !== dataFromChild) {
            return false;
        }

        // Filter by searchQuery if it's not blank
        if (dataFromSearch && !containsSearchQuery(item, dataFromSearch)) {
            return false;
        }
        return true;
    });

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const startIndex = currentPage * itemsPerPage;
    const endIndex = currentPage === totalPages - 1 ? filteredData.length : startIndex + itemsPerPage;
    const displayedItems = filteredData.slice(startIndex, endIndex);
    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
    };
    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };
    // apartment background image
    const backgroundImage = apartmentImage || backgroundImageApartment;
    // house carousel
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
    };

    useEffect(() => {
        const getRandomImages = () => {
            const usedImages = Object.values(backgroundImageApartment);
            const availableImages = Array.from({ length: 20 }, (_, index) => index + 1);
            const uniqueRandomImages = [];
            let randomNumber = Math.floor(Math.random() * 20) + 1;

            while (uniqueRandomImages.length < 5) {
                const randomNumber = Math.floor(Math.random() * availableImages.length);
                const randomImage = availableImages.splice(randomNumber, 1)[0];

                if (!usedImages.includes(randomImage)) {
                    uniqueRandomImages.push(randomImage);
                }
            }

            return uniqueRandomImages.map((imageNumber) =>
                `https://kodinyumba.app/media/Stock/House/house${imageNumber}.jpg`
            );
        };

        const updateBackgroundImages = () => {
            const updatedImages = { ...backgroundImageApartment };

            for (const unitDetail of housesApartment) {
                const apartmentId = unitDetail.house_id;
                if (!updatedImages[apartmentId]) {
                    updatedImages[apartmentId] = getRandomImages();
                }
            }
            setBackgroundImageApartment(updatedImages);
        };

        updateBackgroundImages();
    }, [housesApartment, backgroundImageApartment]);

    const renderCarouselItems = (unitId) => {
        const uniqueImages = housesApartment.filter((house) => house.house_id === unitId)
        const Images = uniqueImages[0].house_image

        return Images.map((imageUrl, index) => (
            <Slider {...settings} key={index}>

                <Image src={imageUrl.image} alt={`Image ${index + 1}`} fluid />

            </Slider>
        ));
    };

    const toggleHearts = async (houseId) => {
        const prevHeartStateValue = heartState[houseId];

        // Update the UI to immediately reflect the change.
        setHeartState((prevHeartState) => ({
            ...prevHeartState,
            [houseId]: !prevHeartState[houseId],
        }));

        if (accessToken) {
            // Check if the user is logged in.
            const parsedTokens = JSON.parse(accessToken);
            const access = parsedTokens.access;

            // Headers access token.
            const config = {
                headers: { Authorization: `Bearer ${access}` }
            };

            // Decoding the token to get the user id.
            const decodedToken = jwtDecode(accessToken);
            const userId = decodedToken.user_id;

            // If the user has already liked the apartment, delete it.
            if (prevHeartStateValue) {
                try {
                    const res = await serverUrl.get(`/landlord/viewers-favourites/?house=${houseId}&user=${userId}`, config);
                    if (res.data.count === 1) {
                        // If the user has liked the apartment, delete it.
                        await serverUrl.delete(`/landlord/viewers-favourites/${res.data.results[0].id}`, config);
                    }
                } catch (error) {
                    console.log(error);
                }
            } else {
                // If the user hasn't liked the apartment, add it.
                const likedApartment = {
                    house: houseId,
                    user: userId,
                    user_name:currentUser.full_name,
                };
                try {
                    await serverUrl.post(`/landlord/viewers-favourites/`, likedApartment, config);
                } catch (error) {
                    console.log(error);
                }
            }
        } else {
            toast.error('Kindly Login so that you can be able to like a house');
        }
    };

    // randomise the images for my properties
    const [backgroundImageProperty, setBackgroundImageProperty] = useState([]);

    useEffect(() => {
        const getRandomImages = () => {
            const images = [];
            for (let i = 1; i <= 5; i++) {
                let randomNumber = Math.floor(Math.random() * 10) + 1;
                while (randomNumber === 0 || randomNumber === -1 || images.includes(randomNumber)) {
                    randomNumber = Math.floor(Math.random() * 10) + 1;
                }
                images.push(randomNumber);
            }

            const imageUrls = images.map((randomNumber) => `https://kodinyumba.app/media/Stock/hd/Property/apartment${randomNumber}.jpg`);
            setBackgroundImageProperty(imageUrls);
        };

        getRandomImages();
    }, []);
    const [showModal, setShowModal] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    const handleImageClick = (index) => {
        setSelectedImageIndex(index);
        setShowModal(true);
    };

    const [showModalAmmenities, setShowModalAmmenities] = useState(false);

    const handleAmenities = () => {
        setShowModalAmmenities(true)
    }

    const [showModalFacilities, setShowModalFacilities] = useState(false);

    const handleFacilities = () => {
        setShowModalFacilities(true)
    }
    const nearby = [
        {
            name: "Church",
            distance: "120m",
            image: "https://kodinyumba.app/media/church.png"
        },
        {
            name: "Hospital",
            distance: "500m",
            image: "https://kodinyumba.app/media/hospital.png"
        },
        {
            name: "Mall",
            distance: "640m",
            image: "https://kodinyumba.app/media/shopping-center.png"
        },
        {
            name: "School",
            distance: "20m",
            image: "https://kodinyumba.app/media/school.png"
        },
        {
            name: "Mosque",
            distance: "120m",
            image: "https://kodinyumba.app/media/mosque.png"
        },
        {
            name: "Tarmac Road",
            distance: "50m",
            image: "https://kodinyumba.app/media/road.png"
        },
        {
            name: "Police Station",
            distance: "400m",
            image: "https://kodinyumba.app/media/police-station.png"
        }
    ]

    function toCamelCase(str) {
        return str.replace(/_/g, ' ');
    }

    return (
        <>
            <SpecialNavigation onData={handleSearchData} {...props} />
            <TopMenuFilter onData={handleChildData} {...props} />
            <div className='dashboard_topheader'></div>
            <div className='selected-property'>
                <div className='topSelectedProperty'>
                    {selectedApartmentImages.length > 0 && (
                        <Carousel >
                            {selectedApartmentImages.map((imageUrl, index) => (
                                <Carousel.Item key={index}>
                                    <img
                                        src={imageUrl.image}
                                        className="propertyCarousel"
                                        onClick={() => handleImageClick(index)}
                                    />
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    )}
                    <div className='ImageHouseName'>
                        {selectedApartment && (<>
                            <span className='properties'>{selectedPropertyDetails.location}</span>
                            <h2>{selectedPropertyDetails.name}</h2>
                            <Button className='text-white bg-dark border-0 bottomButtonMap' href={`/#/map/${id}`}>
                                <Link to={`/#/map/${id}`} className='text-white text-decoration-none'>
                                    Show map
                                    <Image src={Map} style={{ width: "20%", marginLeft: "0.2rem" }} />
                                </Link>
                            </Button>
                            
                            <Button className='btn btn-warning btn-sm w-10 text-white selectedPropertyShowAll'>
                                <Link to={`/all-photos-propert/${selectedPropertyDetails.id}`} className='text-white text-decoration-none ms-2'>
                                    <Image src={Grid} style={{ width: "9%",height:"2vh" }} /> Show all photos                        {selectedApartment.property_image}
                                </Link>
                            </Button>
                        </>)}
                    </div>
                </div>

                <Modal
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    centered
                    dialogClassName='modal-100w'
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Apartment View Gallery</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Carousel
                            activeIndex={selectedImageIndex}
                            onSelect={(index) => setSelectedImageIndex(index)}
                        >
                            {backgroundImageProperty.map((image, index) => (
                                <Carousel.Item key={index}>
                                    <Image src={image} alt={`Image ${index}`} fluid />
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='secondary' onClick={() => setShowModal(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                <div className='d-flex w-25 mt-5  ButtonsTopAmenities'>
                    <Button className='btn btn-sm btn-warning text-white ms-1' onClick={() => handleAmenities()}>Amenities</Button>
                    <Button className='btn btn-sm btn-warning text-white ms-3' onClick={() => handleFacilities()}>Nearby Facilities</Button>
                </div>
                {/* ammenities modal */}
                <Modal
                    show={showModalAmmenities}
                    onHide={() => setShowModalAmmenities(false)}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title style={{ color: "#E6B800" }}>Amenities</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            {selectedAmenities.length > 0 ?
                                selectedAmenities.map((amenity) => (
                                    <Col md={6} className="amenties text-start d-flex">
                                        <p className='ms-3 amenityText'> {amenity.name}</p>

                                        <Image src={amenity.icon} style={{ width: "10%", marginLeft: "0.5rem", marginBottom: "1rem" }} />
                                    </Col >
                                ))
                                :
                                <p style={{ color: "#95a5a6" }}>No amenities found</p>
                            }


                        </Row>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='secondary' onClick={() => setShowModalAmmenities(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                {/* nearby Facilities */}
                <Modal
                    show={showModalFacilities}
                    onHide={() => setShowModalFacilities(false)}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title style={{ color: "#E6B800" }}>Nearby Facilities</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            {nearbyFacilities.length > 0 ?
                                nearbyFacilities.map((essential) => (
                                    <Col md={6} className='d-flex'>
                                        <Image src={essential.icon_image} style={{ width: "10%", height: "55%", marginTop: "0.9rem", marginLeft: "0.7rem" }} />
                                        <label style={{ marginTop: "0.8rem", marginLeft: "1.5rem" }} className='amenityText'><span>{essential.icon}</span> is {essential.distance} meters away</label>

                                    </Col>

                                ))
                                :
                                <p style={{ color: "#95a5a6" }}>No nearby facilities were found</p>

                            }



                        </Row>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='secondary' onClick={() => setShowModalFacilities(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                
                <Row>
                    <Col md={9} sm={6} className='d-flex w-100' style={{ height: 'fit-content' }}>
                        {housesApartment.length === 0 ?
                            <p className='notfoundp'>NO UNITS FOUND</p>
                            :
                            displayedItems.map((unitDetail, index) =>
                                <>
                                    <Card className='house'>
                                        <Card.Body>
                                            <div className="image-container">
                                                <Link to={`/selected-house/${unitDetail.house_id}`} target="_blank" className='text-decoration-none'>

                                                    <Slider
                                                        {...settings}
                                                        interval={null}
                                                        className="houseUnit">{renderCarouselItems(unitDetail.house_id)}
                                                    </Slider>
                                                </Link>

                                                <div className="badge text-capitalize">{unitDetail.rent_type}</div>

                                                <div className='heartBadge' onClick={() => toggleHearts(unitDetail.house_id)}>
                                                    {heartState[unitDetail.house_id] ? (
                                                        <img src={Heart} alt="Heart" />
                                                    ) : (
                                                        <img src={Heart2} alt="Heart2" />
                                                    )}
                                                </div>
                                                <Link to={`/selected-house/${unitDetail.house_id}`} target="_blank" className='text-decoration-none'>

                                                    <div className="badge_rent text-capitalize">{toCamelCase(unitDetail.type_rent_status)}</div>
                                                    <div className='d-flex justify-content-between'>
                                                        <p className="text-start houseTitle" style={{ marginBottom: "0" }} >{unitDetail.name}</p>
                                                    </div>
                                                </Link>

                                            </div>
                                            {unitDetail.type_rent_status === 'for_sale' ?
                                                <div className="houseRentP">Ksh {unitDetail.rent.toLocaleString()}</div>
                                                :
                                                <div className="houseRentP">Ksh {unitDetail.rent.toLocaleString()}/month</div>


                                            }
                                            <p className="houseName fw-normal text-capitalize" style={{ marginBottom: "20px", position: "absolute", bottom: "6%" }}>{toCamelCase(unitDetail.type_of_house)}</p>
                                            <div className="d-flex" style={{ paddingTop: "0.8rem" }}>
                                                <span className='d-flex' style={{ marginTop: ":1rem" }}>
                                                    <span style={{ fontSize: "0.8rem", marginRight: "0.4rem", marginTop: ":1rem" }}>{unitDetail.bedroom} Bedrooms</span>
                                                    <span style={{ fontSize: "1.8rem", marginTop: "-1.2rem" }}>.</span>
                                                    <span style={{ fontSize: "0.8rem", marginLeft: "0.4rem" }}>{unitDetail.bathroom} Bathroom</span>
                                                </span>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </>
                            )
                        }
                        {housesApartment.length === 0 ?
                            <></>
                            :
                            <div className="pagination" style={{ margin: '0 auto', padding: "2rem", width: 'inherit' }}>
                                <button onClick={handlePrevPage} disabled={currentPage === 0}>
                                    &lt;
                                </button>
                                <span style={{ alignSelf: "center" }}>{`${startIndex + 1}-${endIndex} of ${housesApartment.length}`}</span>
                                <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
                                    &gt;
                                </button>
                            </div>
                        }

                    </Col>
                    <Col md={3} sm={8} className='d-none'>
                        <Card className='topProperty'>
                            <Row>
                                <Col md={12} className='fw-bold mb-2 fs-6 text-start' style={{ color: "#E6B800" }}>
                                    AMENITIES
                                </Col>
                            </Row>
                            <Row>
                                {amenitiesApartment.length > 0 ?
                                    amenitiesApartment.map((amenity) => (
                                        <Col md={12} className="amenties text-start d-flex">
                                            <Image src={amenity.icon} style={{ width: "15%", marginBottom: "1rem" }} />
                                            <p className='ms-3 amenityText'> {amenity.name}</p>
                                        </Col >
                                    ))
                                    :
                                    <p style={{ color: "#95a5a6" }}>No amenities found for this property kindly add one</p>
                                }
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </div >

            <Footer />
        </>
    )
}
export default SelectedProperty